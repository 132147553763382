<template>
  <li class="collection-item avatar">
    <circle-icon v-if="fixedIcon" :name="fixedIcon" :on-click="onClick"></circle-icon>
    <circle-icon v-if="!fixedIcon && !!item.icon" :name="item.icon" :on-click="onClick"></circle-icon>
    <div class="item-name"><router-link :to="url">{{ item.name }}</router-link></div>
    <div class="item-status">
      <slot></slot>
    </div>
    <row-button icon="fas fa-trash" :click-handler="onDelete" title="Kustuta"></row-button>
  </li>
</template>

<script>
import RowButton from "@/components/shared/RowButton";
import CircleIcon from "@/components/shared/CircleIcon";
export default {
  name: "CollectionItemWithStatus",
  components: {CircleIcon, RowButton},
  props: {
    item: {type: Object, required: true},
    url: {type: String, required: true},
    fixedIcon: {type: String, required: false},
    onIconClick: {type: Function},
    onDelete: {type: Function, required: true},
  },
  methods: {
    onClick() {
      if (this.onIconClick != null)
        this.onIconClick()
    }
  }
}
</script>

<style scoped>
.item-name {font-weight: bold}
</style>
