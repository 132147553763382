export default {
	buildOptions(groupName, idOptions) {
		return idOptions.map(opt => ({id: opt, label: this[groupName][opt] || '?'+opt}))
	},

	EVENT_LABELS: {
		toggle_null: '! lülitas',

		online: 'hakkas toimima',
		offline: 'kättesaamatu',

		day: 'algas päev',
		morning: 'algas hommik',
		afternoon: 'pärastlõuna',
		evening: 'algas õhtu',
		night: 'algas öö',

		off: 'kustus',
		on: 'läks põlema',

		unlocked: 'lukust lahti',
		locked: 'lukku',

		idle: 'jäi seisma',
		pumping: 'läks käima',
		running: 'läks käima',

		released: 'nupu vabastus',
		pressed: 'nupu vajutus',

		silent: 'liikumine lakkas',
		movement: 'toimus liikumine',

		closed: 'sulgus',
		open: 'avanes',

		freezing: 'läks jäiseks',
		cold: 'läks külmaks',
		warm: 'läks soojaks',
		hot: 'läks palavaks',

		dry: 'läks kuivaks',
		humid: 'läks niiskeks',

		dark: 'läks pimedaks',
		dim: 'läks hämaraks',
		bright: 'läks valgeks',

		vacant: 'muutus vabaks',
		occupied: 'hõivati',
		disarmed: 'valvest maha',
		armed: 'valvesse',
		arming: 'läks valveviitesse',
		alarm: 'läks häiresse',
		relaxed: 'läks lõõgastavrežiimi',
		party: 'pidu algas',
		romantic: 'algas romantika',
		movienight: 'algas filmiõhtu',
		workmode: 'algas töörežiim',
		lightsOff: 'tuled kustu',
		spotLight: 'süttis kohtvalgus',
		backlight: 'taustavalgus',
		maxLights: 'kõik tuled põlema',
		autoLight: 'olekusse: liikumisel valgeks',

		controllerConnected_null: 'Ühendus taastus',
		controllerDisconnected_null: 'Ühendus katkes',
		controllerDead_null: 'Ühendus hangus',
		controllerConnectionFailed_null: 'Viga ühendumisel',
	},
	COMMAND_LABELS: {
		toggle_null: 'lülita ümber',

		off: 'kustuta',
		on: 'pane põlema',

		unlocked: 'lukust lahti',
		locked: 'lukku',

		idle: 'seiska',
		pumping: 'käivita',
		running: 'käivita',

		vacant: 'märgi vabaks',
		occupied: 'märgi hõivatuks',
		disarmed: 'valvest maha',
		armed: 'pane valvesse',
		arming: 'alusta valvestamist',
		alarm: 'pane häiresse',
		relaxed: 'pane lõõgastavrežiimi',
		party: 'alusta pidu',
		romantic: 'alusta romantikat',
		movienight: 'alusta filmiõhtut',
		workmode: 'alusta töörežiimi',
		lightsOff: 'kustuta tuled',
		spotLight: 'lülita kohtvalgusele',
		backlight: 'lülita taustavalgusele',
		maxLights: 'kõik tuled põlema',
		autoLight: 'olekusse: liikumisel valgeks',
	},
	STATUS_LABELS: {
		ok: 'tavaolek',
		error: 'viga',
		alert: 'häires',

		online: 'toimib',
		offline: 'on kättesaamatu',

		day: 'päev',
		morning: 'hommik',
		afternoon: 'pärastlõuna',
		evening: 'õhtu',
		night: 'öö',

		off: 'ei põle',
		on: 'põleb',

		unlocked: 'lukust lahti',
		locked: 'lukus',

		idle: 'seisab',
		running: 'töötab',
		pumping: 'pumpab',

		released: 'vaba',
		pressed: 'alla vajutatud',

		silent: 'liikumist pole',
		movement: 'toimub liikumine',

		closed: 'suletud',
		open: 'avatud',

		freezing: 'jäine',
		cold: 'külm',
		warm: 'soe',
		hot: 'palav',

		dry: 'kuiv',
		humid: 'niiske',

		dark: 'pime',
		dim: 'hämar',
		bright: 'valge',
		blinding: 'ülivalge',

		vacant: 'vaba',
		occupied: 'hõivatud',
		disarmed: 'valveta',
		armed: 'valves',
		arming: 'valvesse minemas',
		alarm: 'häires',
		professional: 'töine',
		relaxed: 'lõõgastav',
		party: 'pidu',
		romantic: 'romantiline',
		movienight: 'filmiõhtu',
		workmode: 'töörežiimis',
		lightsOff: 'tuled kustus',
		spotLight: 'kohtvalgus',
		backlight: 'taustavalgus',
		maxLights: 'täisvalgus',
		autoLight: 'liikumisel valgeks',

		controllerConnected: 'Ühenduses',
		controllerDisconnected: 'Ühenduseta',
		unknown: 'olek teadmata',
	},

	I2C_LABELS: {
		SPEED_10kHz: "kuni 2m (10 kHz)",
		SPEED_1kHz: "kuni 10m (1kHz)",
		SPEED_100Hz: "kuni 25m (100 Hz)",
	},

	TERMINAL_LABELS: {
		input: "digisisend",
		adc: "analoogsisend",
		output: "digiväljund",
		i2c: "I²C andur",
	},

	TERMINAL_GROUP_LABELS: {
		input: "Digisisendid",
		adc: "Analoogsisendid",
		output: "Digiväljundid",
		i2c: "I²C andurid",
	},

	DEVICE: {
		lamp: 'Lamp',
		lock: 'Lukk',
		pump: 'Pump',
		motor: 'Mootor',
		wallSwitch: 'Seinalüliti',
		pir: 'Liikumisandur',
		doorSensor: 'Ukseandur',
		analogWallSwitch: 'Seinalüliti',
		analogPir: 'Liikumisandur',
		analogDoorSensor: 'Ukseandur',
		temperatureSensorLM75A: 'Temperatuuriandur LM75A',
		temperatureSensorMCP9808: 'Temperatuuriandur MCP9808',
		humiditySensorHTU21D: 'Niiskusandur HTU21D',
		lightSensorMAX44009: 'Valgusandur MAX44009',
	},
}
