<template>
  <div class="input-field col" :class="css">
    <textarea :id="id" class="materialize-textarea" type="text"
              :value="value"
              :placeholder="placeholder"
              :class="{invalid: $root.errors[id]}"
              @input="$emit('input', $event.target.value)">
    </textarea>
    <label :for="id">{{ label }}</label>
    <span class="helper-text" :data-error="$root.errors[id]">{{ $root.errors[id] }}</span>
  </div>
</template>

<script>
import M from "materialize-css";

export default {
  name: "FormTextArea",
  props: {
    id: {type: String, required: true},
    css: {type: String, required: false},
    value: {type: String, required: false},
    label: {type: String, required: true},
    placeholder: {type: String, required: false},
  },
  mounted() {
    const elem = document.getElementById(this.id)
    M.textareaAutoResize(elem)
  },
  computed: {
    mapErrorLabel(id, errorOptions) {
      return this.getErrorLabel(id, errorOptions)
    }
  }
}
</script>
