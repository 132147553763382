<template>
  <div class="input-field col" :class="css">
    <select :id="id" class="abc"
            :value="value"
            :class="{invalid: $root.errors[id]}"
            @change="onChange($event)">
      <option v-if="hasEmptyOption"></option>
      <option v-for="opt in options" :key="opt.id" :value="opt.id" :selected="opt.id === value">{{ opt.label }}</option>
    </select>
    <label :for="id">{{ label }}</label>
    <span class="helper-text" :data-error="$root.errors[id]"></span>
  </div>
</template>

<script>
import M from "materialize-css";

export default {
  name: "FormSelect",
  props: {
    id: {type: String, required: true},
    css: {type: String, required: false},
    value: {required: false},
    label: {type: String, required: true},
    options: {type: Array, required: false},
    hasEmptyOption: {type: Boolean, required: false, default: true},
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.value)
      this.$emit('change', event.target.value)
    },
    reinit() {
      const elem = document.getElementById(this.id)
      M.FormSelect.init(elem, {dropdownOptions:{container:document.body}})
    }
  },
  mounted() {this.reinit()},
  watch: {
    options() {this.$nextTick(() => this.reinit())},
    value() {this.$nextTick(() => this.reinit())},
  },
}
</script>
