<template>
  <div>
    <!-- Tavasuuruses menüü -->
    <div class="navbar-fixed">
      <nav id="navbar" v-show="visible">
        <div class="nav-wrapper">
          <a href="#" data-target="navbarmob" class="sidenav-trigger"><i class="material-icons">menu</i></a>
          <a href="#" class="brand-logo">SaxaHome</a>

          <ul class="right hide-on-small-only hide-on-tiny-vertical">
            <li><a href="/profile" class="tooltipped" data-tooltip="Profiil" title="Profiil"><i class="fas fa-user"></i>{{ principal.givenName }}</a></li>
            <li><a class="tooltipped" data-tooltip="Filter" title="Filter" @click="openFilter"><i class="fas fa-search"></i>Otsi</a></li>
            <li><a class="tooltipped" data-tooltip="Lahku" @click="logout" title="Lahku"><i class="fas fa-sign-out-alt"></i></a></li>
          </ul>
          <ul id="nav-mobile" class="right hide-on-med-and-down hide-on-tiny-vertical">
            <li><router-link to="/dashboard" class="tooltipped" data-tooltip="Töölaud"><i class="fas fa-columns"></i> Töölaud</router-link></li>
            <li><router-link to="/settings" class="tooltipped" data-tooltip="Seadistus"><i class="fas fa-tools"></i> Seadistused</router-link></li>
            <li><router-link to="/log" class="tooltipped" data-tooltip="Sündmuste logi"><i class="fas fa-list-ul"></i> Sündmused</router-link></li>
          </ul>
          <div class="progress" id="data-is-loading">
            <div class="indeterminate"></div>
          </div>
        </div>
      </nav>
      <search-bar v-if="filterEntryVisible" @change="applyFilter" @cancel="hideFilter"></search-bar>
    </div>

    <!-- Mobiilne menüü -->
    <ul class="sidenav" id="navbarmob">
      <li class="group-title">
        <i class="far fa-user"></i>{{ principal.givenName }}
        <i class="fas fa-home icon-padding"></i>{{ principal.accountName }}
      </li>
      <li><div class="divider"></div></li>
      <li><router-link to="/dashboard" class="sidenav-close"><i class="fas fa-columns"></i>Töölaud</router-link></li>
      <li><router-link to="/log" class="sidenav-close"><i class="fas fa-list-ul"></i>Sündmuste ajalugu</router-link></li>
      <li><router-link to="/logout" @click="logout" class="sidenav-close"><i class="fas fa-sign-out-alt"></i>Lahku</router-link></li>
      <li><div class="divider"></div></li>
      <li class="group-title"><i class="fas fa-tools"></i>Seadistused</li>
      <li><router-link to="/controller" class="sidenav-close"><i class="far fa-hdd"></i>Kontroller</router-link></li>
      <li><router-link to="/modules" class="sidenav-close"><i class="fas fa-microchip"></i>Moodulid</router-link></li>
      <li><router-link to="/devices" class="sidenav-close"><i class="far fa-lightbulb"></i>Seadmed</router-link></li>
      <li><router-link to="/timers" class="sidenav-close"><i class="fas fa-stopwatch"></i>Taimerid</router-link></li>
      <li><router-link to="/rules" class="sidenav-close"><i class="fas fa-bezier-curve"></i>Reeglid</router-link></li>
      <li><router-link to="/zones" class="sidenav-close"><i class="fas fa-border-style"></i>Tsoonid</router-link></li>
      <li><router-link to="/floor-plans" class="sidenav-close"><i class="fas fa-home"></i>Korruseplaanid</router-link></li>
    </ul>
  </div>
</template>

<script>
import SearchBar from "@/components/frame/SearchBar";
export default {
  name: "Navbar",
  components: {SearchBar},
  props: {
    principal: {type: Object, required: true},
  },
  mounted() {
    var elems = document.querySelectorAll('.sidenav');
    this.M.Sidenav.init(elems, {closeOnClick: true})
//    this.$root.sideNavInstance = this.M.Sidenav.getInstance(document.getElementById('navbarmob'))
  },
  data() {
    return {
      visible: true,
      filterEntryVisible: false,
    }
  },
  methods: {
    logout() {
      window.axios.post("/principal/logout").then(() => {
        this.window.location.href = this.$data.env.VUE_APP_UIBASEURL + "/"
      })
      return false;
    },
    openFilter() {this.$data.visible = !(this.$data.filterEntryVisible = true)},
    hideFilter() {this.$data.visible = !(this.$data.filterEntryVisible = false)},
    applyFilter(newFilter) {this.$data.filter = newFilter; this.hideFilter()}
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Orbitron');

nav {margin-bottom: 3em;}
@media only screen and (max-width: 600px) {
  nav {margin-bottom: 1em;}
}
nav .brand-logo {
  margin-left: 0.5em;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 24pt;
}
a, a svg.svg-inline--fa, a:hover, a:hover svg.svg-inline--fa {color: white}
.sidenav li>a {color: #ef6c00}
.sidenav {opacity: 0.9}
li svg.svg-inline--fa {font-size: 1.5rem; margin-right: 1rem; width: 1.5rem; vertical-align: sub}
.nav-wrapper i, .nav-wrapper svg {font-size: 1.5rem; margin-right: .5rem; vertical-align: text-bottom}
nav .progress {background-color: $secondary-color}
nav .progress .indeterminate {background-color: white}
.group-title {padding-left: 2rem}
.icon-padding {margin-left: 2rem}
.navbar-small {display: none}
@media only screen and (max-height: $small-vertical-breakpoint) {
  .hide-on-tiny-vertical {display: none}
  nav {width: 25%}
  nav .brand-logo {font-size: 12pt; position: relative; left: 3rem}
  nav, nav a.sidenav-trigger, nav a.sidenav-trigger i {line-height: 2rem; height: 2rem; display: block}
  .navbar-fixed {position: fixed}
}
@media only screen and (max-height: $small-vertical-breakpoint) and (max-width: $medium-screen) {
  nav {width: 33.3333%}
}
@media only screen and (max-height: $small-vertical-breakpoint) and (max-width: $small-screen) {
  nav {width: 100%}
}

</style>
