<template>
  <button-bar>
    <icon-button :handler="saveHandler" icon="far fa-save" label="Salvesta"></icon-button>
    <icon-button css="cancel-button" :handler="cancelHandler" icon="fas fa-times" label="Tühista"></icon-button>
  </button-bar>
</template>

<script>

import ButtonBar from "@/components/shared/ButtonBar";
import IconButton from "@/components/shared/IconButton";
import {router} from "@/main"

export default {
  name: "SaveCancelBar",
  props: {
    saveHandler: {type: Function, required: false},
    cancelHandler: {type: Function, required: false, default: () => router.go(-1)},
  },
  components: {IconButton, ButtonBar},
}
</script>

<style scoped lang="scss">
button.cancel-button {background-color: $cancel-color}
</style>
