<template>
  <div>
    <h1>Seadistused</h1>
    <ul>
      <li><router-link to="/controller"><i class="far fa-hdd"></i>Kontroller</router-link></li>
      <li><router-link to="/modules"><i class="fas fa-microchip"></i>Moodulid</router-link></li>
      <li><router-link to="/devices"><i class="far fa-lightbulb"></i>Seadmed</router-link></li>
      <li><router-link to="/timers"><i class="fas fa-stopwatch"></i>Taimerid</router-link></li>
      <li><router-link to="/rules"><i class="fas fa-bezier-curve"></i>Reeglid</router-link></li>
      <li><router-link to="/zones"><i class="fas fa-border-style"></i>Tsoonid</router-link></li>
      <li><router-link to="/floor-plans"><i class="fas fa-home"></i>Korruseplaanid</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SettingsLinks"
}
</script>

<style scoped>
li {line-height: 3rem}
li svg.svg-inline--fa {font-size: 1.5rem; margin-right: 1rem; width: 1.5rem}
</style>
