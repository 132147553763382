<template>
  <div class="row row-with-button">
    <ul class="collection">
      <li v-for="item in value" :key="item.id" class="collection-item avatar valign-wrapper">
        <circle-icon :name="icon"></circle-icon>
        {{ itemRefs[item.itemId].label }} / {{ statusLabelProvider(item.type === 'toggle' ? 'toggle_null' : item.status) }}
        <span v-show="!!item.delay"> / {{ item.delay }} msek hiljem</span>
        <row-button title="Kustuta" icon="fas fa-trash" :click-handler="() => discard(item.id)"></row-button>
      </li>
      <li class="collection-item avatar entry-form">
        <circle-icon name="fas fa-plus" title="Alusta uue lisamist" :on-click="showEntry" v-show="!entryVisible" css="clickable"></circle-icon>
        <circle-icon name="far fa-trash-alt" title="Tühista lisamine" :on-click="hideEntry" v-show="entryVisible" css="clickable"></circle-icon>
        <empty-list-placeholder :list="value" :label="labelEmpty" v-show="!entryVisible"></empty-list-placeholder>
        <div class="row" v-show="entryVisible">
          <form-select :id="id+'Id'" :css="inputCss" :label="labelItem" v-model="itemId" :options="options" @change="updateOptions"></form-select>
          <form-select :id="id+'Status'" :css="inputCss" :label="labelStatus" v-model="itemStatus" :options="statusOptions"></form-select>
          <form-input v-show="hasDelay" :id="id+'Delay'" :css="inputCss" label="Käskluse viide (ms)" v-model="itemDelay"></form-input>
        </div>
        <row-button icon="fas fa-angle-double-up" title="Lisa" :click-handler="commit" v-show="entryVisible"></row-button>
      </li>
    </ul>
  </div>
</template>

<script>
import FormSelect from "@/components/shared/FormSelect";
import RowButton from "@/components/shared/RowButton";
import CircleIcon from "@/components/shared/CircleIcon";
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import FormInput from "@/components/shared/FormInput";

export default {
  name: "RuleSettingsSection",
  components: {FormInput, EmptyListPlaceholder, CircleIcon, RowButton, FormSelect},
  props: {
    id: {type: String},//id-st ehitatakse select box'de id-d
    value: {type: Array},//sündmuste/filtrite/käskude loend, mida selle komponendiga ehitatakse
    itemRefs: {type: Object},//itemite mäp, kust saab id alusel nime ja tüübi teada
    options: {type: Array},//nende itemite loend (RestOption), mille hulgast saab itemeid valida
    icon: {type: String},//loendireal see ikoon
    labelItem: {type: String},//itemi dropdowni silt
    labelStatus: {type: String},//itemi staatuse silt
    labelEmpty: {type: String},//silt juhuks, kui list on veel tühi
    statusLabelProvider: {type: Function},//funktsioon, mis tõlgib status-väärtuse sildiks
    hasDelay: {type: Boolean},//kas kuvada viite-kasti
  },
  data() {
    return {
      entryVisible: false,
      itemId: null,
      itemStatus: null,
      itemDelay: null,
      statusOptions: [],
    }
  },
  computed: {
    inputCss() {return "s12 " + (this.hasDelay ? "m4" : "m6")}
  },
  methods: {
    discard(id) {
      const newValue = this.value.filter(item => item.id != id)
      this.$emit('change', newValue)
      this.$emit('input', newValue)
    },
    commit() {
      if (!this.itemStatus) {
        return
      }
      const record = {
        id: new Date().getTime(),
        itemId: this.itemId,
        type: (this.itemStatus === 'toggle' ? 'toggle' : 'setStatus'),
        status: (this.itemStatus === 'toggle' ? null : this.itemStatus),
        delay: this.itemDelay,
      }
      const newValue = [...this.value]
      newValue.push(record)
      this.$data.itemId = null
      this.$data.itemStatus = null
      this.$data.itemDelay = null
      this.$emit('change', newValue)
      this.$emit('input', newValue)
      this.updateWidgets()
    },
    updateOptions() {
      switch(this.itemRefs[this.itemId].type) {
        case 'Devices':
          window.axios.get("/device/" + this.itemId + "/statuses").
            then(response => {
              this.$data.statusOptions = response.data.map(status => ({id: status, label: this.statusLabelProvider(status)}))
              if (this.hasDelay)
                this.$data.statusOptions.push({id: 'toggle', label: this.statusLabelProvider('toggle_null')})
            })
          break
        case 'Timers':
          window.axios.get("/timer/" + this.itemId + "/statuses").
            then(response => this.$data.statusOptions = response.data.map(status => ({id: status, label: this.statusLabelProvider(status)})))
          break
        case 'Zones':
          window.axios.get("/zone/" + this.itemId + "/statuses").
            then(response => {
              this.$data.statusOptions = response.data.map(status => ({id: status, label: this.statusLabelProvider(status)}))
            if (this.hasDelay)
              this.$data.statusOptions.push({id: 'toggle', label: this.statusLabelProvider('toggle_null')})
          })
          break
        case 'Modules':
          this.$data.statusOptions = [{id: 'online', label: this.statusLabelProvider('online')}, {id: 'offline', label: this.statusLabelProvider('offline')}]
          break
      }
    },
    showEntry() {
      this.$data.entryVisible = true
    },
    hideEntry() {
      this.$data.entryVisible = false
    },
  }
}
</script>

<style scoped lang="scss">
.collection .collection-item.avatar .secondary-content {top: 1.25rem; right: 0px} //rea lõpu nupu positsioneerimine
.entry-form .input-field, .entry-form .row {margin-bottom: 0px}
.collection .collection-item.avatar.entry-form .secondary-content {top: 2.5rem}
.collection .collection-item.entry-form {border-bottom: 0px}
.collection {overflow: visible} //et avatud dropdowni ära ei lõikaks
.entry-form i {color: $secondary-color}
</style>
