<template>
  <div>
    <span class="right" v-show="online">
      <i class="fas fa-check fa-2x"></i> Toimib
    </span>
    <span class="right error" v-show="!online">
      <i class="fas fa-exclamation-triangle fa-2x"></i> Katki
    </span>
    <h1>Moodul: {{ settings.name }}</h1>

    <div class="row">
      <ul class="tabs" ref="tabs">
        <li class="tab col s4 m3"><a href="#settings">Üldandmed</a></li>
        <li class="tab col s4 m3"><a href="#devices">Klemmid</a></li>
        <li class="tab col s4 m3"><a href="#log">Logi</a></li>
      </ul>
      <div id="settings" class="col s12">
        <module-settings-form ref="form"></module-settings-form>
      </div>
      <div id="devices" class="col s12">
        <module-terminals ref="terminals" id=""></module-terminals>
      </div>
      <div id="log" class="col s12">
        Logi
      </div>
    </div>
  </div>

</template>

<script>
import ModuleSettingsForm from "@/components/saxamodule/ModuleSettingsForm";
import ModuleTerminals from "@/components/saxamodule/ModuleTerminals";

export default {
  name: "ModuleDetails",
  components: {ModuleTerminals, ModuleSettingsForm},
  data() {
    return {
      settings: {},
      isEditMode: false,
      online: false,
      tabs: null,
    }
  },
  mounted() {
    this.$data.moduleId = this.window.location.pathname.split('/').slice(-2)[0]
    const url = "/module/" + this.$data.moduleId + "/settings"
    this.$data.tabs = this.M.Tabs.init(this.$refs.tabs, {})
    setTimeout(() => this.$data.tabs.select('settings'), 100)
    window.axios.get(url).then(response => {
      this.$refs.form.update(response.data)
      this.$refs.terminals.update(response.data)
      this.$data.settings = response.data
      window.axios.post("/message", {id: this.$data.moduleId, type: 'i2cScan'})
      setTimeout(() => this.$data.tabs.select('settings'), 300)
    })
  },
  methods: {
    i2cScanResponse(event) {this.$refs.terminals.notifyScanResponse(event)},
    snapshotResponse(event) {this.$data.online = (event.value[this.$data.moduleId].status === 'online')},
    changed(event) {
      if (event.id == this.$data.moduleId)
        this.$data.online = !(event.status === 'offline')
    },
  },
}
</script>

<style scoped lang="scss">
.right svg.svg-inline--fa {vertical-align: middle; margin-right: 0.5rem}
.right.error {color: $error-color}
</style>
