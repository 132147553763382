<template>
<div>
  <div>
    <h1 v-show="!settings.id">Uus seade</h1>
    <h1 v-show="settings.id">{{ settings.name }}</h1>
  </div>

  <div class="row">
    <chip tooltip="Moodul" css="s12 m6">
      <i class="fas fa-microchip"></i>
      <span class="hide-on-small-only">Moodul:</span>
      {{ settings.moduleName }}
    </chip>
    <chip tooltip="Klemm" css="s12 m6">
      <i class="fas fa-neuter"></i>
      <span class="hide-on-small-only">Klemm:</span>
      {{ this.terminalType() }} #{{ this.$data.settings.pin }}
    </chip>
  </div>

  <h2>Üldinfo</h2>
  <div class="row">
    <form-input id="name" class="s12 m6" placeholder="nt. Laelamp" label="Seadme nimetus" v-model="settings.name"></form-input>
    <form-select v-model="settings.type" id="type" css="s12 m6" @change="onTypeChange"
                 label="Seadme tüüp" v-bind:options="deviceOptions"></form-select>
    <form-switch label-on="NC" label-off="NO" css="s12" v-model="settings.reverseStatus" v-show="isOnOff"
      tooltip-on="Erijuhul on NC (normally closed) ehk vaikeolekus on pinge peal: näiteks pullup takistiga lüliti või muu sisend"
      tooltip-off="Üldjuhul on NO (normally open) ehk vaikeolekus on ahel katkestatud"
    ></form-switch>
  </div>
  <div class="row">
    <form-text-area css="s12" id="notes" placeholder="Siia saad lisada seadme kohta märkusi" label="Märkused" v-model="settings.notes"></form-text-area>
  </div>

  <h2 v-show="isNumeric">Anduri seadistus</h2>
  <div class="row" v-if="isNumeric">
    <form-input id="hysteresis" class="s12 m6 l4" placeholder="nt. 0" :label="'Hüsterees'+unitSuffix" v-model="settings.hysteresis"></form-input>
    <form-select v-model="settings.sensitivity" id="sensitivity" css="s12 m6 l4"
                 :label="'Tundlikkus'+unitSuffix" v-bind:options="sensitivityOptions"></form-select>
    <form-select v-model="settings.decimalPlaces" id="decimalPlaces" css="s6 l2"
                 label="Numbri vorming" :options="decimalOptions"></form-select>
    <form-input id="unit" class="s6 l2" placeholder="nt.°C" label="Ühik" v-model="settings.unit"></form-input>
  </div>

  <h2 v-show="isNumeric">Vahemikud</h2>
  <div class="row" v-if="isNumeric">
    <form-select v-model="settings.lowerStatus" id="lowerStatus" css="s12 m4" label="Alumine olek"
                 :options="statusOptions" @change="delayIconUpdate('lowerStatus')"></form-select>
    <form-select v-model="settings.upperStatus" id="upperStatus" css="s12 m4" label="Ülemine olek"
                 :options="statusOptions" @change="delayIconUpdate('upperStatus')"></form-select>
    <form-input id="toggleValue" class="s12 m4" :label="'Piirväärtus'+unitSuffix" v-model="settings.toggleValue"></form-input>
    <div class="col s12 m6">
      <chip tooltip="Alumine piirkond" css="s12 m6">
        <span v-if="settings.lowerStatus"><i :class="lowerStatusIcon"></i></span>
        {{ settings.minimum }}...{{ settings.toggleValue }} {{ settings.unit }} - {{ lowerStatusLabel }}
      </chip>
    </div>
    <div class="col s12 m6">
      <chip tooltip="Ülemine piirkond" css="s12 m6">
        <span v-if="settings.upperStatus"><i :class="upperStatusIcon"></i></span>
        {{ settings.toggleValue }}...{{ settings.maximum }} {{ settings.unit }} - {{ upperStatusLabel }}
      </chip>
    </div>
  </div>

  <save-cancel-bar :save-handler="save"></save-cancel-bar>
</div>
</template>

<script>
import Chip from "@/components/shared/Chip";
import Labels from "@/assets/js/labels"
import Icons from "@/assets/js/iconmap"
import FormInput from "@/components/shared/FormInput";
import FormSelect from "@/components/shared/FormSelect";
import FormTextArea from "@/components/shared/FormTextArea";
import SaveCancelBar from "@/components/shared/SaveCancelBar";
import FormSwitch from "@/components/shared/FormSwitch";

export default {
  name: "DeviceSettings",
  components: {FormSwitch, SaveCancelBar, FormTextArea, FormSelect, FormInput, Chip},
  computed: {
    unitSuffix() {return this.$data.settings.unit ? ' (' + this.$data.settings.unit + ')' : ''},
    isNumeric() {return (this.$data.settings.terminalType === 'i2c') || (this.$data.settings.terminalType === 'adc')},
    isOnOff() {return (this.$data.settings.terminalType === 'output' || this.$data.settings.terminalType === 'input')},
    step() {return (Number(this.$data.settings.maximum || 100) - Number(this.$data.settings.minimum || 0)) / 20},
    lowerStatusLabel() {return Labels.STATUS_LABELS[this.$data.settings.lowerStatus]},
    upperStatusLabel() {return Labels.STATUS_LABELS[this.$data.settings.upperStatus]},
    lowerStatusIcon() {return Icons.statusIcons[this.$data.settings.lowerStatus]},
    upperStatusIcon() {return Icons.statusIcons[this.$data.settings.upperStatus]},
  },
  methods: {
    terminalType() {return Labels.TERMINAL_LABELS[this.$data.settings.terminalType]},
    save() {
      window.axios.put('/device', this.$data.settings)
        .then(response => {this.$data.settings = response.data; this.showSaveOk("Salvestatud");})
    },
    initStatusOptions(opts) {this.$data.statusOptions = Labels.buildOptions('STATUS_LABELS', opts)},
    initSensitivityOptions(opts) {this.$data.sensitivityOptions = (opts ? opts.map(o => ({id: o+'', label: o+''})) : null)},
    onTypeChange() {
      if (this.$data.settings.type) {
        window.axios.get(`/device/type-options?deviceType=${this.$data.settings.type}`).then(response => {
          this.initStatusOptions(response.data.statusOptions)
          this.initSensitivityOptions(response.data.sensitivityOptions)
          this.$data.settings.unit = response.data.unit
          this.$data.settings.decimalPlaces = response.data.decimals
          this.$data.settings.hysteresis = response.data.hysteresis
          this.$data.settings.minimum = response.data.minimum
          this.$data.settings.maximum = response.data.maximum
          this.$data.settings.toggleValue = response.data.toggleValue
          this.$data.settings.lowerStatus = response.data.statusOptions[0]
          this.$data.settings.upperStatus = response.data.statusOptions[response.data.statusOptions.length - 1]
          this.$data.settings.sensitivity = response.data.sensitivity
          this.updateWidgets()
          this.delayIconUpdate('lowerStatus')//Sunni SVG-ikooni uuendama
          this.delayIconUpdate('upperStatus')//Sunni SVG-ikooni uuendama
        })
      }
    },
    delayIconUpdate(attrName) {//seda on vaja seetõttu, et SVG ikoon ei joonista ennast muidu uuesti
      this.$nextTick(() => {
        const val = this.$data.settings[attrName]
        this.$data.settings[attrName] = null
        this.$nextTick(() => this.$data.settings[attrName] = val)
      })
    }
  },
  data() {
    return {
      decimalOptions: [{id: 0, label: '12'}, {id: 1, label: '12.3'}, {id: 2, label: '12.34'}, {id: 3, label: '12.345'}, {id: 4, label: '12.3456'}],
      deviceOptions: [],
      statusOptions: [],
      sensitivityOptions: [],
      settings: {
        toggleValue: null,
        reverseStatus: false,
        name: null, type: null, notes: null,
        hysteresis: null, decimalPlaces: null, unit: null, sensitivity :null,
      },
    }
  },
  mounted() {
    var url
    if (this.$route.params.id)
      url = `/device/${this.$route.params.id}/settings`
    else url = `/device/new/${this.$route.params.moduleId}/${this.$route.params.terminalType}/${this.$route.params.pinId}`
    window.axios.get(url).then(response => {
      this.$data.deviceOptions = response.data.deviceTypes.map(o => ({id: o.id, label: Labels.DEVICE[o.id]}))
      this.$data.settings = response.data
      if (this.$route.params.id) {//olemasoleva seadme puhul määra staatused/tundlikkused ära (uue seadme puhul mitte)
        this.initStatusOptions(response.data.statusOptions)
        this.initSensitivityOptions(response.data.sensitivityOptions)
      }
      else if (this.$data.settings.type)//kui uue seadme puhul backend saatis tüübi
        this.onTypeChange()//siis initsialiseeri vahemike sektsioon
      this.updateWidgets()
    })
  }
}
</script>

<style scoped>

</style>
