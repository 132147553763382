<template>
  <div>
    <div>
      <h1 v-if="!settings.name">Uus taimer</h1>
      <h1 v-if="settings.name">{{ settings.name }}</h1>
    </div>

    <div class="row">
      <form-input id="name" class="s12" placeholder="nt. Minu taimer" label="Taimeri nimetus" v-model="settings.name"></form-input>
      <time-picker id="start" label="Päev alates kellast" css="s12 m6" v-model="settings.start"></time-picker>
      <time-picker id="end" label="Päev kuni kellani" css="s12 m6" v-model="settings.end"></time-picker>
      <form-text-area css="s12" id="notes" placeholder="Siia saad lisada taimeri kohta märkusi" label="Märkused" v-model="settings.notes"></form-text-area>
    </div>
    <save-cancel-bar :save-handler="save"></save-cancel-bar>
  </div>
</template>

<script>
import FormInput from "@/components/shared/FormInput";
import FormTextArea from "@/components/shared/FormTextArea";
import SaveCancelBar from "@/components/shared/SaveCancelBar";
import TimePicker from "@/components/shared/TimePicker";
export default {
  name: "TimerSettings",
  components: {TimePicker, SaveCancelBar, FormTextArea, FormInput},
  data() {
    return {
      settings: {},
    }
  },
  mounted() {
    let url = "/timer/new"
    if (!this.window.location.pathname.endsWith("/new"))
      url = "/timer/" + this.window.location.pathname.split('/').slice(-2)[0] + "/settings"
    window.axios.get(url).then(response => {
      this.$data.settings = response.data
      this.updateWidgets()
    })
  },
  methods: {
    save() {
      window.axios.put("/timer", this.$data.settings).
        then(() => window.location.href = '/timers')
    },
  },
}
</script>
