<template>
  <router-link class="btn-floating waves-effect waves-light right tooltipped" data-tooltip="Lisa uus" :to="route">
    <i class="fas fa-plus"></i>
  </router-link>
</template>

<script>
export default {
  name: "AddButton",
  props: {route: {type: String, required: true}}
}
</script>

<style scoped lang="scss">
a svg.svg-inline--fa.fa-plus {color: white}
.btn-floating {margin-top: -0.5rem}
.btn-floating:hover {background-color: lighten($secondary-color, 15%); transition: all 0.2s linear}
</style>
