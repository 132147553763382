<template>
  <div>
    <add-button route="/timer/new"></add-button>
    <h1>Taimerid</h1>
    <list-filter @change="applyFilterToList"></list-filter>

    <empty-list-placeholder :list="list" label="Ühtegi taimerit pole veel seadistatud. Lisa <a href='/timer/new'>siit</a> esimene taimer!"></empty-list-placeholder>
    <ul class="collection">
      <collection-item-with-status v-for="item in list" :key="item.id" :item="item" :url="'/timer/'+item.id+'/settings'"
                                   :on-delete="() => deleteTimer(item.id)" v-show="!item.hidden">
        <i class="fas fa-info-circle"></i> {{ item.statusLabel }}
      </collection-item-with-status>
    </ul>
  </div>
</template>

<script>
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import CollectionItemWithStatus from "@/components/CollectionItemWithStatus";
import BaseStatusCollection from "@/components/shared/BaseStatusCollection";
import AddButton from "@/components/shared/AddButton";
import ListFilter from "@/components/shared/ListFilter";
export default {
  name: "TimerList",
  extends: BaseStatusCollection,
  components: {ListFilter, AddButton, CollectionItemWithStatus, EmptyListPlaceholder},
  data() {
    return {
      list: [],
    }
  },
  mounted() {this.fetchListFrom('/timers')},
  methods: {
    deleteZone(id) {window.axios.delete("/timer/" + id).then(() => window.location.reload())},
  },
}
</script>
