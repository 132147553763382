<template>
  <pan-zoom-wrapper :image-url="imageUrl" :width="imageWidth" :height="imageHeight"
                    :click-handler="canvasClick" :mouse-tracker="trackMouseMove" ref="zoomWrapper">
    <polyline :points="svgPath" fill="none" :stroke="secondaryColor" stroke-width="4" stroke-opacity="0.75" stroke-linejoin="round"></polyline>
    <polygon v-for="contour in contours" :key="contour.id"
             :fill="polyColor(contour)" fill-opacity="0.3" :stroke="polyColor(contour)" stroke-opacity="0.3"
             stroke-width="2" stroke-linejoin="round" stroke-linecap="round" :points="polygon(contour)"
             @click="() => onPolygonClick(contour)" @touchend="() => onPolygonClick(contour)"></polygon>
  </pan-zoom-wrapper>
</template>

<script>
import Const from "@/assets/js/const"
import PanZoomWrapper from "@/components/floorplan/PanZoomWrapper";

export default {
  name: "FloorPlanEditor",
  components: {PanZoomWrapper},
  props: {
    imageUrl: {type: String, required: false},
    imageWidth: {type: Number, required: false},
    imageHeight: {type: Number, required: false},
    contours: {type: Array, required: false},
  },
  data() {
    return {
      activeContourId: null,
      showPolyLayer: true,
      primaryColor: Const.primaryColor,
      secondaryColor: Const.secondaryColor,
      isDrawingMode: false,
      pathPoints: [],
      polygons: [],
      svgPath: '',
    }
  },
  methods: {
    reset(startDrawingMode) {
      this.$data.isDrawingMode = startDrawingMode
      this.$data.pathPoints = []
      this.$data.activeContourId = null
    },
    polygon(contour) {
      return contour.polygon.map(el => el.x+','+el.y).join(' ')
    },
    polyColor(contour) {
      return (contour.id === this.$data.activeContourId ? Const.secondaryColor : Const.primaryColor)
    },
    onPolygonClick(contour) {
      this.$data.showPolyLayer = false
      this.$nextTick(() => {
        this.$data.showPolyLayer = true
        this.$data.activeContourId = contour.id
        this.$emit('click', contour)
      })
    },
    canvasClick(event) {
      if (!this.$data.isDrawingMode)
        return
      const ev = this.snap(event)
      this.$data.pathPoints.push([ev.x, ev.y])
      if (this.$data.pathPoints.length > 1 && ev.x === this.$data.pathPoints[0][0] && ev.y === this.$data.pathPoints[0][1]) {
        this.$data.svgPath = ''
        this.$data.isDrawingMode = false
        this.$emit('change', this.$data.pathPoints)
      }
    },
    trackMouseMove(event) {
      if (!this.$data.isDrawingMode)
        return
      const ev = this.snap(event)
      this.$data.svgPath = this.buildSvgPath() + ev.x + ',' + ev.y
    },
    buildSvgPath() {
      return this.$data.pathPoints.map(([x,y]) => x+','+y+' ').join(' ')
    },
    snap(event) {
      let pt = {x: event.offsetX, y: event.offsetY}
      if (this.$data.pathPoints.length < 1)
        return pt
      if (event.shiftKey)
        return this.snapXY(this.snapXY(pt, this.$data.pathPoints[this.$data.pathPoints.length - 1]), this.$data.pathPoints[0])
      else return pt
    },
    snapXY(pt, pathPt) {
      return {
        x: (Math.abs(pt.x-pathPt[0]) < (this.imageWidth / 30) ? pathPt[0] : pt.x),
        y: (Math.abs(pt.y-pathPt[1]) < (this.imageHeight / 30) ? pathPt[1] : pt.y),
      }
    },
    rezoom() {
      this.$refs.zoomWrapper.rezoom()
    }
  },
}
</script>

<style scoped>
.plan-width-provider *:focus {outline: none} /* Et plaanile klikkides ei tekiks musta kasti ümber*/
.vue-pan-zoom-scene {overflow: hidden}
.plan-canvas {background-repeat: no-repeat}
</style>

