<template>
  <div class="buttonbar row">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ButtonBar"
}
</script>

<style scoped>
</style>
