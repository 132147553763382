<template>
  <div>
    <h2>Kontroller</h2>

    <div class="row">
      <ul class="tabs">
        <li class="tab col s3"><a class="active" href="#details">Details</a></li>
        <li class="tab col s3"><a href="#log">Log</a></li>
      </ul>
    </div>

    <div id="details">
      <div class="row">
        <form-input css="s12 m6" id="name" placeholder="nt. Kodukontroller" label="Nimetus" v-model="details.name"></form-input>
        <form-input css="s12 m6" id="serial" placeholder="nt. 43758947694" label="Seerianumber" v-model="details.serial" error="See on kellegi teise seerianumber"></form-input>
        <form-text-area css="s12" id="notes" placeholder="Siia saad teha märkmeid kontrolleri kohta" label="Märkused" v-model="details.notes"></form-text-area>
      </div>

      <div class="row valign-wrapper">
        <a @click="onCardDownload"><i class="fas fa-download fa-2x"></i> Kaabelduse kaart</a>
      </div>

      <save-cancel-bar :save-handler="submit"></save-cancel-bar>
    </div>

    <controller-log></controller-log>
  </div>
</template>

<script>
import FormInput from "@/components/shared/FormInput";
import FormTextArea from "@/components/shared/FormTextArea";
import ControllerLog from "@/components/controller/ControllerLog";
import SaveCancelBar from "@/components/shared/SaveCancelBar";
export default {
  name: "ControllerDetails",
  components: {SaveCancelBar, ControllerLog, FormTextArea, FormInput},
  data() {
    return {details: {name: null, serial: null, notes: null}}
  },
  mounted() {
    this.loadDetails()
  },
  methods: {
    loadDetails() {
      window.axios.get("/controller").then(response => {
        this.$data.details = response.data
        this.updateWidgets()
      })
    },
    onCardDownload() {
      const headers = {accept: 'application/json'}
      window.axios.get('/configuration-card', headers).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([JSON.stringify(response.data)], {type: 'text/plain'}));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = 'wiring-card.json'
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    submit() {
      window.axios.put("/controller", this.$data.details).
        then(() => window.location.href = '/modules')
    }
  },
}
</script>

<style scoped>
a svg.svg-inline--fa {vertical-align: middle; margin-right: 0.5rem}
</style>
