<template>
  <span class="icon-wrapper valign-wrapper" @click="onClick">
    <i v-if="isMaterial" class="icon material-icons" :class="css" :title="title">{{ name }}</i>
    <i v-if="!isMaterial" class="icon icon-fa" :class="name+' '+css" :title="title"></i>
  </span>
</template>

<script>
/**
 * CircleIcon represents a font-awesome or materialize icon (depends on icon name) with surrounding circle.
 */
export default {
  name: 'CircleIcon',
  props: {
    name: {type: String, required: false},
    css: {type: String, required: false},
    title: {required: false},
    onClick: {type: Function, required: false, default: () => {}},
  },
  computed: {
    isMaterial() {
      return this.name && !this.name.startsWith('fa')
    }
  },
}
</script>

<style scoped lang="scss">
.icon {
  border: 2px solid $icon-color;
  color: $icon-color;
  padding: 5px;
  border-radius: 50%;
}
.icon.error {
  border: 2px solid $error-color;
  color: $error-color;
}
.icon.disabled {
  border: 2px solid $disabled-color;
  color: $disabled-color;
}
li.active .icon, .icon.clickable {
  border: 2px solid $secondary-color;
  color: $secondary-color;
}
.material-icons {
  font-size: 1.5rem;
}
.icon-fa {
  width: 36px;
  height: 36px;
}
.collection-item.avatar .icon-wrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
}
</style>
