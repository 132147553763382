<template>
  <div>
    <div>
      <h1>Korruseplaan: {{ settings.name }}</h1>
    </div>

    <div class="row">
      <div class="col s12 m6 l4">
        <ul class="collapsible popout" ref="collapsibleRef">
          <li>
            <div class="collapsible-header">Plaani üldandmed</div>
            <div class="collapsible-body">
              <div class="row">
                <form-input id="name" class="s12" placeholder="nt. Põhiplaan" label="Plaani nimetus" v-model="settings.name"></form-input>
                <form-file-upload id="fileName" css="s12" icon-name="far fa-file-image" v-model="settings.fileName"
                                  tooltip="Vali pildifail alusplaaniga"
                                  upload-url="/floor-plan/upload-file" :upload-complete-handler="onUploadComplete"></form-file-upload>
                <form-text-area css="s12" id="notes" placeholder="Lisainfo plaani kohta" label="Märkused" v-model="settings.notes"></form-text-area>
                <a class="btn-floating waves-effect waves-light right tooltipped" v-show="imageUrl"
                   data-tooltip="Lisa uus klikitav ala" @click="addContour"><i class="material-icons">add</i></a>
              </div>
            </div>
          </li>
          <li v-show="showContourSettings">
            <div class="collapsible-header has-trash-icon">
              <div class="right" v-show="currentContour"><a @click="discardContour"><i class="fas fa-trash"></i></a></div>
              Klikiala
            </div>
            <div class="collapsible-body">
              <div class="row">
                <form-select id="type" :options="contourOptions" label="Klikiala viitab" v-model="type" css="s12"></form-select>
                <form-select v-show="type==='zone'" id="zone" :options="settings.zoneOptions" label="Tsooni nimi"
                             v-model="zoneId" css="s12" @change="setDrawingMode"></form-select>
                <form-select v-show="type==='plan'" id="planLink" :options="settings.planOptions" label="Sihtplaan"
                             v-model="planId" css="s12" @change="setDrawingMode"></form-select>
              </div>
              <div class="row" v-show="isDrawingMode">
                <i class="fas fa-info-circle fa-2x"></i> Kliki paremal korruseplaanil, et piirata klikitav ala.
                Ala sulgemiseks SHIFT-kliki alguspunktil!
              </div>
            </div>
          </li>
        </ul>
        <save-cancel-bar :save-handler="save"></save-cancel-bar>
      </div>
      <div class="col s12 m6 l8" v-show="imageUrl">
        <floor-plan-editor ref="planEditor" :image-url="imageUrl" @change="contourClosed" @click="onPolygonClick"
                           :image-width="settings.imageWidth" :image-height="settings.imageHeight"
                           :contours="settings.contours"></floor-plan-editor>
      </div>
      <empty-list-placeholder v-if="!imageUrl" label="Korruseplaani jaoks on vaja üles laadida aluspilt" :list="null"></empty-list-placeholder>
    </div>

  </div>
</template>

<script>
import FormInput from "@/components/shared/FormInput";
import FormTextArea from "@/components/shared/FormTextArea";
import SaveCancelBar from "@/components/shared/SaveCancelBar";
import FormFileUpload from "@/components/shared/FormFileUpload";
import M from 'materialize-css'
import FloorPlanEditor from "@/components/floorplan/FloorPlanEditor";
import FormSelect from "@/components/shared/FormSelect";
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";

export default {
  name: "FloorPlanSettings",
  components: {EmptyListPlaceholder, FormSelect, FloorPlanEditor, FormFileUpload, SaveCancelBar, FormTextArea, FormInput},
  data() {
    return {
      settings: {
        contours: [],
      },
      collapsible: null,
      showContourSettings: false,
      type: null,
      zoneId: null,
      planId: null,
      imageUrl: null,
      imageWidth: null,
      imageHeight: null,
      contourOptions: [{id: 'zone', label: 'tsoonile'}, {id: 'plan', label: 'teisele korruseplaanile'}],
      isDrawingMode: false,
      currentContour: null,
    }
  },
  mounted() {
    this.$data.collapsible = M.Collapsible.init(this.$refs.collapsibleRef)
    this.$data.collapsible.open(0)
    let url = "/floor-plan/new"
    if (!this.window.location.pathname.endsWith("/new")) {
      const id = this.window.location.pathname.split('/').slice(-2)[0]
      url = `/floor-plan/${id}/settings`
    }
    window.axios.get(url).then(response => {
      response.data.contours.forEach((contour, index) => contour.id = index)
      this.$data.settings = response.data
      if (response.data.id) {
        this.$data.imageUrl = `${this.$root.env.VUE_APP_BASEURL}/floor-plan/${response.data.id}/image/${response.data.imageUrlSuffix}`
        this.$refs.planEditor.rezoom()
      }
      this.updateWidgets()
    })
  },
  methods: {
    onUploadComplete(dimensions) {
      this.$data.imageUrl = null//unusta korraks eelmise üleslaetud pildi link, kuna see on uue lingiga ühesugune, siis brauser ei lae pilti uuesti
      this.$nextTick(() => {
        this.$data.imageUrl = `${this.$root.env.VUE_APP_BASEURL}/floor-plan/uploaded-image`
        this.$data.settings.imageWidth = dimensions.width
        this.$data.settings.imageHeight = dimensions.height
        this.$refs.planEditor.rezoom()
      })
    },
    save() {
      const data = JSON.parse(JSON.stringify(this.$data.settings))
      data.planOptions = data.zoneOptions = null
      window.axios.put("/floor-plan", data).then(() => window.location.href = '/floor-plans')
    },
    addContour() {
      this.$data.currentContour = null
      this.$data.showContourSettings = true
      this.$data.collapsible.open(1)
      this.$data.isDrawingMode = false
      this.$data.type = null
      this.$data.zoneId = null
      this.$data.planId = null
      this.$refs.planEditor.reset(false)
    },
    discardContour() {
      this.$data.settings.contours = this.$data.settings.contours.filter(contour => contour != this.$data.currentContour)
      this.$data.showContourSettings = false
      setTimeout(() => this.$data.collapsible.open(0), 100)
    },
    setDrawingMode() {
      this.$data.isDrawingMode = true
      this.$refs.planEditor.reset(true)
    },
    contourClosed(contour) {
      this.$data.settings.contours.push({
        id: new Date().getTime(),
        zoneId: this.$data.zoneId,
        planId: this.$data.planId,
        polygon: contour.map(el => ({x: el[0], y: el[1]}))
      })
      this.$data.showContourSettings = false
      this.$data.collapsible.open(0)
    },
    onPolygonClick(contour) {
      this.$data.currentContour = contour
      this.$data.showContourSettings = true
      this.$data.collapsible.open(1)
      this.$data.type = (contour.zoneId != null ? 'zone' : 'plan')
      this.$data.zoneId = contour.zoneId
      this.$data.planId = contour.planId
    }
  },
}
</script>

<style scoped lang="scss">
.missing-plan {background-color: $placeholder-color}
.svg-inline--fa {vertical-align: middle}
.has-trash-icon {display: block}//ilma selleta ei lähe ikoon paremäärde
.has-trash-icon svg {color: $error-color; vertical-align: text-top}
</style>
