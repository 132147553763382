<template>
  <div class="switch" :class="css">
    <label>
      <span :class="{tooltipped: tooltipOff}" :data-tooltip="tooltipOff">{{ labelOff }}</span>
      <input type="checkbox" :checked="value" @change="$emit('input', !value)">
      <span class="lever"></span>
      <span :class="{tooltipped: tooltipOn}" :data-tooltip="tooltipOn">{{ labelOn }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "FormSwitch",
  props: {
    labelOff: {type: String, required: false},
    labelOn: {type: String, required: false},
    tooltipOff: {type: String, required: false},
    tooltipOn: {type: String, required: false},
    css: {type: String, required: false},
    value: {required: true}
  },
}
</script>
