<template>
	<div class="card-panel row valign-wrapper">
		<div class="col s3 l2">
			<i class="material-icons medium icon-margin">error</i>
		</div>
		<div class="col s9 l10">
			<h2>
				Sind pole veel registreeritud!
				<br><br>
				Esialgu pääseb ligi ainult käputäis katsejäneseid
			</h2>
		</div>
	</div>
</template>

<script>
	export default {
		name: "UnknownUser"
	}
</script>

<style scoped lang="scss">
.card-panel {margin-top: 25vh; color: white; background-color: $error-color}
</style>
