<template>
  <div>
    <router-link class="waves-effect waves-light btn right" :to="settingsLink">
      <span class="hide-on-small-only">Seadista</span>
      <i class="material-icons right">build</i>
    </router-link>
    <h1>{{ status.name }}</h1>
    <div class="row">
      <div v-if="status.isOnOffInput" class="col s12">
        <div class="chip">
          <span :class="{'off-state': !status.isReverseStatus, 'on-state': status.isReverseStatus}" v-if="!onOffStatus">{{ status.defaultStatus }}</span>
          <span :class="{'off-state': status.isReverseStatus, 'on-state': !status.isReverseStatus}" v-if="onOffStatus">{{ status.activeStatus }}</span>
        </div>
      </div>
      <div v-if="status.isOnOffOutput" class="col s12">
        <div class="switch">
          <label>
            off
            <input type="checkbox" v-model="onOffStatus" @change="onToggle">
            <span class="lever"></span>
            on
          </label>
        </div>
      </div>
      <div class="col s12 m6">
        <h2>Graafik</h2>
        <line-chart></line-chart>
      </div>
      <div class="col s12 m6">
        <h2>Sündmused</h2>
        <log-list :list="status.log"></log-list>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/shared/LineChart";
import LogList from "@/components/LogList";
import LogFormat from "@/assets/js/logformat"

export default {
  name: "DeviceStatus",
  components: {LogList, LineChart},
  data() {
    return {
      settingsLink: "#",
      onOffStatus: false,
      status: {
        log: []
      },
    }
  },
  mounted() {
    this.$data.settingsLink = "/device/" + this.window.location.pathname.split('/').slice(-2)[0] + "/settings"
    const url = "/device/" + this.window.location.pathname.split('/').slice(-2)[0] + "/status"
    window.axios.get(url).then(response => {
      response.data.log = response.data.log.map(record => LogFormat.formatRecord(record))
      this.$data.status = response.data
      this.updateWidgets()
    })
  },
  methods: {
    snapshotResponse(event) {
      const entry = event.value[this.$data.status.id]
      if (entry != null) {
        this.updateStatus(this.$data.status.currentStatus = entry.status)
      }
    },
    changed(event) {
      this.updateStatus(event.status)
      if (event.id == this.$data.status.id && !!event.status) {
        this.$data.status.log = [LogFormat.toRecord(event), ...this.$data.status.log]
        if (this.$data.status.log != null && this.$data.status.log.length > 8) {
          this.$data.status.log.pop()
        }
      }
    },
    updateStatus(status) {
      if (this.$data.status.isOnOffOutput || this.$data.status.isOnOffInput)
        this.$data.onOffStatus = (status != this.$data.status.defaultStatus)
    },
    onToggle() {
      const message = {id: this.$data.status.id, type: 'toggle'}
      window.axios.post("/message", message).then(() => {})
    }
  },
}
</script>

<style scoped lang="scss">
.switch, .chip {margin: 2rem}
.on-state {color: $secondary-color}
</style>
