<template>
  <span class="chip tooltipped" :class="css" :data-tooltip="tooltip">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "Chip",
  props: {tooltip: {type: String}, css: {type: String}},
  mounted() {
    this.updateWidgets()
  }
}
</script>

<style scoped>
.chip {border-left: 1rem solid transparent; border-right: 1rem solid transparent}
.chip svg.svg-inline--fa {margin-right: 1rem; vertical-align: middle; font-size: 1.5rem}
</style>
