<template>
  <div class="plan-width-provider" ref="planWidthProvider">
    <div class="canvas-clipper" :style="{'height': canvasHeight+'px', 'background-color': `${bgColor}`}">
      <div ref="planCanvas" class="plan-canvas"
           @touchend="clickHandlerProxy" @click="clickHandlerProxy" @mousemove="mouseTrackerProxy"
           :style="{'background-image': `url(${imageUrl})`, width: width+'px', height: height+'px'}">
        <svg width="100%" height="100%">
          <slot></slot>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import panzoom from "panzoom"

export default {
  name: "PanZoomWrapper",
  props: {
    imageUrl: {type: String, required: false},
    width: {type: Number, required: false, default: 200},
    height: {type: Number, required: false, default: 200},
    bgColor: {type: String, required: false, default: '#fff'},
    clickHandler: {type: Function, required: false},
    mouseTracker: {type: Function, required: false},
  },
  data() {
    return {
      canvasHeight: 200,
      pzInstance: null,
    }
  },
  created() {
    window.addEventListener('resize', this.rezoom)
  },
  mounted() {
    this.$data.pzInstance = panzoom(this.$refs.planCanvas, {initialX: 0, initialY: 0, initialZoom: 1})
  },
  methods: {
    rezoom() {
      const conditionFn = () => this.$refs.planWidthProvider && this.$refs.planWidthProvider.clientWidth > 0
      this.runWhenTrue(conditionFn, () => {
        const clientWidth = this.$refs.planWidthProvider.clientWidth
        const zoomY = (window.innerHeight * 0.9) / this.height
        let zoom = clientWidth / this.width
        if (zoomY < zoom)
          zoom = zoomY
        this.$data.canvasHeight = this.height * zoom
        const x = (clientWidth > this.width*zoom ? (clientWidth - this.width*zoom) / 2 : 0)
        this.$data.pzInstance.zoomAbs(x, 0, zoom)
        this.$data.pzInstance.moveTo(x, 0)
      })
    },
    clickHandlerProxy(event) {
      if (this.clickHandler) this.clickHandler(event)
    },
    mouseTrackerProxy(event) {if (this.mouseTracker) this.mouseTracker(event)},
    runWhenTrue(conditionFunction, actionFunction) {
      this.$nextTick(() => {
        if (!conditionFunction()) {
          let limit = 20
          const intervalHandler = setInterval(() => {
            if (conditionFunction()) {
              clearInterval(intervalHandler)
              actionFunction()
            }
            else if (--limit <= 0)
              clearInterval(intervalHandler)
          }, 50)
        }
        else actionFunction()
      })
    },
  }
}
</script>

<style>
.plan-width-provider *:focus {outline: none} /* Et plaanile klikkides ei tekiks musta kasti ümber */
</style>

<style scoped lang="scss">
.plan-width-provider {padding: 0; width: 100%; margin: 0.5rem}
.plan-canvas {background-repeat: no-repeat; width: 100%; height: 100%}
.canvas-clipper {overflow: hidden; width: 100%; max-height: 90vh}
</style>
