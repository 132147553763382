<template>
  <div class="file-field input-field col" :class="css">
    <div class="btn waves-effect waves-light">
      <span><i :class="iconName"></i>{{ label }}</span>
      <input :id="id" type="file"
             name="file" ref="file"
             :value="value"
             @change="onChange"
             @input="$emit('input', $event.target.value)">
    </div>
    <div class="file-path-wrapper">
      <input class="file-path validate tooltipped" type="text" :class="{invalid: $root.errors[id]}" :data-tooltip="tooltip">
      <span class="helper-text" :data-error="$root.errors[id]"></span>
    </div>
  </div>
</template>

<script>
// Vt https://serversideup.net/uploading-files-vuejs-axios/
export default {
  name: "FormFileUpload",
  props: {
    id: {type: String, required: true},
    css: {type: String, required: false},
    value: {required: false},
    label: {type: String, required: false},
    tooltip: {type: String, required: false},
    iconName: {type: String, required: false},
    uploadUrl: {type: String, required: true},
    uploadCompleteHandler: {type: Function},
  },
  methods: {
    onChange() {
      const formData = new FormData()
      formData.append('file', this.$refs.file.files[0])
      window.axios.post(this.uploadUrl, formData, {
        headers: {'Content-Type': 'multipart/settings-data'},
        onUploadProgress: function( progressEvent ) {
//          let percent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
//          console.log('CP-FUP loaded='+progressEvent.loaded+', total='+progressEvent.total+', ' + percent+'%')
          console.log('FUP ')
          console.log(progressEvent)
        }.bind(this),
      }).then(response => this.uploadCompleteHandler(response.data))
    },
  },
}
</script>

<style scoped>
.file-path-wrapper input.valid[type=text]:not(.browser-default):focus,
.file-path-wrapper input.valid[type=text]:not(.browser-default) {
  border-bottom: 1px solid #9e9e9e;
  -webkit-box-shadow: 0 0 0 0 #9e9e9e;
  box-shadow: 0 0 0 0 #9e9e9e;
}
svg {font-size: 1.2rem}
</style>
