<template>
  <div class="edit-form">
    <div class="row read-only-settings" v-show="!isEditMode">
      <div class="col s12 m4">
        <span class="tooltipped" data-tooltip="Mooduli tüüp">
          <i class="fas fa-microchip fa-2x"></i>
          <span class="hide-on-small-and-down">Mooduli tüüp:</span>
          {{ settings.type }}
        </span>
      </div>
      <div class="col s12 m4">
        <span class="tooltipped" data-tooltip="Seerianumber">
          <i class="fas fa-barcode fa-2x"></i>
          <span class="hide-on-small-and-down">Seerianumber:</span>
          #{{ settings.serial }}
        </span>
      </div>
      <div class="col s12 m4">
        <span class="tooltipped" data-tooltip="I&sup2;C taktsagedus">
          <i class="fab fa-creative-commons-sampling fa-2x"></i>
          <span class="hide-on-small-and-down">I&sup2;C siini pikkus:</span>
          {{ lbl(settings.frequency) }}
        </span>
      </div>
      <div class="col s12" v-show="settings.notes">
        <span class="tooltipped" data-tooltip="Märkused">
          <i class="fas fa-book fa-2x"></i>
          <span class="hide-on-small-and-down">Märkused:</span>
          {{ settings.notes }}
        </span>
      </div>
    </div>
    <div class="row" v-show="!isEditMode">
      <icon-button :handler="() => isEditMode = true" icon="fas fa-edit" label="Muuda"></icon-button>
    </div>

    <div class="row" v-show="isEditMode">
      <chip tooltip="Seerianumber" css="s12 m6"><i class="fas fa-barcode"></i> #{{ settings.serial }}</chip>
      <chip tooltip="Mooduli tüüp" css="s12 m6"><i class="fas fa-microchip"></i> {{ settings.type }}</chip>
    </div>
    <div class="row" v-show="isEditMode">
      <form-input id="name" class="s12 m6" placeholder="nt. Vasakpoolne moodul" label="Mooduli nimetus" v-model="settings.name"></form-input>
      <form-select id="frequency" class="s12 m6" label="I²C siini pikkus" v-model="settings.frequency" :options="frequencyOptions"></form-select>
      <form-text-area css="s12" id="notes" placeholder="Siia saad lisada mooduli kohta märkusi" label="Märkused" v-model="settings.notes"></form-text-area>
    </div>

    <save-cancel-bar v-show="isEditMode" :save-handler="save" :cancel-handler="toggleEditMode"></save-cancel-bar>

  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton"
import FormInput from "@/components/shared/FormInput"
import FormSelect from "@/components/shared/FormSelect"
import FormTextArea from "@/components/shared/FormTextArea"
import SaveCancelBar from "@/components/shared/SaveCancelBar"
import Labels from "@/assets/js/labels"
import Chip from "@/components/shared/Chip";

export default {
  name: "ModuleSettingsForm",
  components: {Chip, SaveCancelBar, FormTextArea, FormSelect, FormInput, IconButton},
  data() {
    return {
      isEditMode: false,
      frequencyOptions: [],
      settings: {},
    }
  },
  mounted() {
    this.$data.frequencyOptions = "10kHz,1kHz,100Hz".split(",").map(val => ({id: 'SPEED_'+val, label: this.lbl('SPEED_'+val)}))
  },
  methods: {
    lbl(key) {return Labels.I2C_LABELS[key]},
    save() {
      let settings = JSON.parse(JSON.stringify(this.$data.settings))
      settings.terminalGroups = null//see põhjustaks serveris deserialiseerimisel vigu
      window.axios.put("/module", settings).
        then(() => {this.showSaveOk("Salvestatud"); this.toggleEditMode();})
    },
    update(settings) {
      this.$data.settings = settings
    },
    toggleEditMode() {
      this.$data.isEditMode = !this.$data.isEditMode
      this.updateWidgets()
    }
  },
}
</script>

<style scoped>
.edit-form {margin-top: 3rem; margin-bottom: 3rem}
.row svg.svg-inline--fa {vertical-align: middle; margin-right: 1rem}
.read-only-settings .col {margin-bottom: 1rem}
</style>
