<script>
import Labels from "@/assets/js/labels";
import Icons from "@/assets/js/iconmap";

export default {
  name: "BaseStatusCollection",
  data() {
    return {
      list: [],
    }
  },
  methods: {
    applyFilterToList() {
      if (this.$root.$data.filter) {
        const words = this.$root.$data.filter.map(word => word.toLowerCase())
        this.$data.list.forEach(item => {
          const lowerName = item.name.toLowerCase()
          item.hidden = !words.find(word => lowerName.includes(word))
        })
      }
      else this.$data.list.forEach(item => item.hidden = false)
      this.$data.list = this.$data.list.map(item => item)
    },
    fetchListFrom(url) {
      window.axios.get(url).then(response => {
        response.data.forEach(item => {
          item.status = 'unknown'
          item.statusLabel = Labels.STATUS_LABELS[item.status]
          item.value = null
          item.icon = Icons.statusIcons['unknown']
        })
        this.$data.list = response.data
        this.applyFilterToList()
        window.axios.post("/message", {type: 'snapshot'})
      })
    },
    snapshotResponse(event) {//töötle snapshotResponse-sündmust
      this.$data.list.filter(item => event.value[item.id]).forEach(item => {
        item.status = event.value[item.id].status
        item.statusLabel = Labels.STATUS_LABELS[item.status]
        item.value = event.value[item.id].value
        item.icon = null//kui kohe õige väärtus sisse panna, siis Vue ei refreshi. seepärast on häkk nextTick-ga
        this.$nextTick(() => item.icon = Icons.statusIcons[item.status] || 'fas fa-question')
      })
    },
    changed(event) {//töötle changed-sündmust
      this.$data.list.filter(item => item.id == event.id).forEach(item => {
        item.status = event.status || item.status
        item.statusLabel = Labels.STATUS_LABELS[item.status]
        item.value = event.value
        item.icon = null//kui kohe õige väärtus sisse panna, siis Vue ei refreshi. seepärast on häkk nextTick-ga
        this.$nextTick(() => item.icon = Icons.statusIcons[item.status] || 'fas fa-question')
      })
    },
  },
}
</script>
