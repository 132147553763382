<template>
  <div class="modal error-box">
    <div class="modal-content">
      <h5 class="valign-wrapper">
        <i class="material-icons medium icon-margin">error</i>
        Midagi läks nihu
      </h5>
      <ul class="collapsible">
        <li>
          <div class="collapsible-header"><i class="material-icons">arrow_downward</i>Vaata lähemalt</div>
          <div class="collapsible-body">
            <table>
              <tbody>
              <tr><td>Teenuse URL</td><td>{{ details.method }} {{ details.url }} =&gt; {{ details.statusCode }}</td></tr>
              <tr><td>HTTP staatusinfo</td><td>{{ details.message }}</td></tr>
              <tr><td>Serveri vastus</td><td>{{ details.responseData }}</td></tr>
              </tbody>
            </table>
          </div>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <a href="#!" class="modal-close waves-effect waves-green btn-flat">Sulge</a>
    </div>
  </div>
</template>

<script>
/**
 * Kasuta nii:
 * 				let errorboxElem = document.querySelectorAll('.error-box')[0]
 *				let instanceBox = M.Modal.getInstance(errorboxElem).open()
 */
export default {
  name: "ErrorBox",
  props: {
    details: {type: Object, required: true},
  }
}
</script>

<style scoped lang="scss">
.error-box .modal-content {color: $error-color;}
</style>
