<template>
  <div>
    <add-button route="/rule/new"></add-button>
    <h1>Reeglid</h1>
    <list-filter @change="applyFilterToList"></list-filter>

    <empty-list-placeholder :list="list" label="Ühtegi reeglit pole veel seadistatud. Lisa <a href='/rule/new'>siit</a> esimene reegel!"></empty-list-placeholder>
    <ul class="collection">
      <collection-item-with-status v-for="item in list" :key="item.id" :item="item" :url="'/rule/'+item.id+'/settings'"
                                   :on-delete="() => deleteRule(item.id)" v-show="!item.hidden" fixed-icon="fas fa-bezier-curve">
      </collection-item-with-status>
    </ul>
  </div>

</template>

<script>
//fas fa-bezier-curve
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import AddButton from "@/components/shared/AddButton";
import ListFilter from "@/components/shared/ListFilter";
import CollectionItemWithStatus from "@/components/CollectionItemWithStatus";
import BaseStatusCollection from "@/components/shared/BaseStatusCollection";
export default {
  name: "RulesList",
  extends: BaseStatusCollection,
  components: {CollectionItemWithStatus, ListFilter, AddButton, EmptyListPlaceholder},
  data() {
    return {
      list: [],
    }
  },
  mounted() {this.fetchListFrom('/rules')},
  methods: {
    deleteRule(id) {
      window.axios.delete("/rule/" + id).then(() => window.location.reload())
    }
  },
}
</script>

<style scoped>

</style>
