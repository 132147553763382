<template>
  <button v-bind:class="css" class="btn waves-effect waves-light left" type="submit" @click="handler">
    <i :class="icon"></i> {{ label }}
  </button>
</template>

<script>
export default {
  name: "IconButton",
  props: ['css', 'label', 'handler', 'icon'],
}
</script>

<style scoped lang="scss">
  button {margin-left: 1em; background-color: $secondary-color}
</style>
