<script>
// Vt https://codesandbox.io/s/93m1lpjrvr?file=/src/components/LineChart.vue:122-730
// Vt https://vue-chartjs.org/guide/
import { Line } from "vue-chartjs";
export default {
  name: "LineChart",
  extends: Line,
  mounted() {
    this.renderChart({
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July"
          ],
          datasets: [
            {
              label: "Data 1",
              data: [2, 10, 5, 9, 0, 6, 20],
              backgroundColor: "transparent",
              borderColor: "rgba(1, 116, 188, 0.50)",
              pointBackgroundColor: "rgba(171, 71, 188, 1)"
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "My Data"
          }
        })
  },
}
</script>

<style scoped>

</style>
