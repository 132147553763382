<template>
  <div id="app">
    <navbar :principal="principal"></navbar>
    <div ref="container" v-show="$root.isLoginPage || $root.isInitialLoadingDone" :class="{container: isContainerVisible}">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
    <error-bar :controller-offline="principal.controllerOffline" :server-offline="serverOffline"></error-bar>
    <error-box :details="$parent.errorDetails"></error-box>
  </div>
</template>

<script>
import ErrorBox from "@/components/frame/ErrorBox";
import ErrorBar from "@/components/frame/ErrorBar";
import Navbar from "@/components/frame/Navbar";
export default {
  name: 'app',
  components: {Navbar, ErrorBar, ErrorBox},
  ws: null,
  data() {
    return {
      principal: {},
      serverOffline: false,
    }
  },
  computed: {
    isContainerVisible() {return !this.$route.path.startsWith('/dashboard')}
  },
  mounted() {
    const elems = document.querySelectorAll('.sidenav')
    this.M.Sidenav.init(elems, {})
    // Tee principal-päring saamaks teada kasutaja nimi
    window.axios.get("/principal").then(response => {
      if (response.data != null && response.data != '') {
        // Kui oleme login-vaates ja sessioon juba eksisteerib, siis suundume edasi sisse
        if (response.data.displayName != null && this.document.getElementById('login-view') != null) {
          this.window.location.href = '/devices'
        }
        this.$data.principal = response.data

        // Kui sessioon oli valiidne, siis pane serveriga püsti sõnumivahetus
        if (response.data != null && response.data.givenName != null)
          this.openEventsWebsocket()
      }
    }).catch(() => {
      if (!this.$root.isLoginPage)
        this.$data.serverOffline = true
    })
  },
  methods: {
    openEventsWebsocket() {
      this.ws = new WebSocket(this.$data.env.VUE_APP_WSURL + '/events')
      this.ws.onopen = this.onWsOpen
      this.ws.onmessage = wsMessage => this.handleWsMessage(wsMessage)
      this.ws.onclose = this.onWsClose
      this.ws.onerror = this.onWsError
    },
    onWsOpen(p1) {
      console.log('Websocket connection open')
      console.log(p1)
      this.$data.serverOffline = false
    },
    onWsClose(event) {
      console.log('Websocket connection closed')
      const hasSessionEnded = (event.code == 1008 || event.code == 4500) //1008:Spring closes due to session timeout; 4500:Saxahome rejects connection due to missing/timed-out session
      this.$data.serverOffline = true
      if (!hasSessionEnded)
        setTimeout(this.openEventsWebsocket, 1000)
    },
    onWsError(err) {
      console.log('Websocket error occured')
      console.log(err)
      this.$data.serverOffline = true
    },
    handleWsMessage(wsMessage) {
      const event = JSON.parse(wsMessage.data);
      this.console.log(event);
      switch(event.type) {
        case 'controllerConnected':
          this.$data.principal.controllerOffline = false;
          break;
        case 'controllerDisconnected':
        case 'controllerDead':
          this.$data.principal.controllerOffline = true;
          break;
      }
      this.$children.forEach(component => {
        // Kui komponendil on sündmuse-nimeline meetod, siis saada sündmus sinna täitmiseks
        if (component[event.type] != null)
          component[event.type](event)
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../node_modules/materialize-css/sass/materialize';

  @import url('https://fonts.googleapis.com/css?family=Roboto');
  #app {
    font-family: 'Roboto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 0px;
    margin-bottom: 50px;
    color: $text-color;
  }
  .container h1, .container h2 {text-align: left; margin-left: .25em}
  .collection {border: 0px;}
  .collection .collection-item.avatar {min-height: 4rem}
  .collection .collection-item.active {color: $secondary-color; background-color: #fff}
  .chip img {margin-left: 0.15rem}
  .chip i {vertical-align: middle}
  a, a svg.svg-inline--fa {color: $secondary-color; cursor: pointer}
  a:hover, a:hover svg.svg-inline--fa {text-decoration: underline; color: lighten($secondary-color, 15%); transition: all 0.2s linear}
  a svg.svg-inline--fa {margin: auto; height: 100%; font-size: 1.6rem}
  input, textarea, select {color: $text-color}
  .toast {background-color: $success-color; margin-left: 2rem; margin-right: 2rem}
  .toast svg {margin-right: 1rem}
  .fade-enter-active {transition: opacity .5s}
  .fade-leave-active {transition: opacity 0s}
  .fade-enter, .fade-leave-to {opacity: 0}
  .btn-floating {text-align: center}
</style>
