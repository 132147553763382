<template>
	<div class="row login-view" id="login-view">
		<div class="col s12 m7 valign-wrapper login-left white-text">
			<div class="centered-content">
				<h1>SaxaHome</h1>
				<div class="center-align login-left-bullets">
					<ul>
						<li>varsti</li>
						<li>läheb</li>
						<li>paremaks</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col s12 m5 valign-wrapper login-right">
			<div class="centered-content login-buttons">
				<div>
					<a :href="`${env.VUE_APP_BASEURL}/oauth2/authorization/facebook`" class="waves-effect waves-light btn button-logo">
						<img class="material-icons left" src="@/assets/facebook.png">
						Jätka Facebookiga
					</a>
				</div>
				<div>
					<a :href="`${env.VUE_APP_BASEURL}/oauth2/authorization/google`" class="waves-effect waves-light btn button-logo">
						<img class="material-icons left" src="@/assets/google.png">
						Jätka Googlega
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LoginPage",
		data() {
			return {
				authenticated: false,
				username: null
			}
		},
    created() {
      this.$root.isLoginPage = true
    },
    mounted() {
			document.getElementById('navbar').style.display = 'none'
			document.getElementById('navbarmob').style.display = 'none'
		},
	}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Orbitron');

.row {margin-bottom: 0px;}
.login-view {position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px}
.login-left {height: 100%; background-color: $primary-color}
.login-left h1 {font-family: 'Orbitron', sans-serif; font-weight: 800;}
.login-left li {list-style-type: initial; text-align: left}
h1 {font-weight: lighter}
.centered-content {margin-left: auto; margin-right: auto; margin-bottom: 25%}
.login-left-bullets {display: inline-block}
.login-right {height: 100%}
.centered-content a {margin-top: 2em}
.login-buttons a {width: 100%}
.button-logo img {margin-top: 2px; margin-right: 1em;}
.button-logo {padding-left: 2px}
@media only screen and (max-width: 600px) {
  .login-right {height: 50%}
  .login-left {height: 50%}
}
</style>
