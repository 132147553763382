import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import 'material-design-icons/iconfont/material-icons.css'
import M from 'materialize-css'
import Login from "./components/Login";
import UnknownUser from "./components/UnknownUser";
import DeviceList from "./components/device/DeviceList";
import DeviceStatus from "./components/device/DeviceStatus";
import ControllerDetails from "@/components/controller/ControllerDetails";
import ModulesList from "@/components/saxamodule/ModulesList";
import SearchModules from "@/components/saxamodule/SearchModules";
import DeviceSettings from "@/components/device/DeviceSettings";

// https://medium.com/front-end-weekly/how-to-use-fon-awesome-5-on-vuejs-project-ff0f28310821
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import LogSearch from "@/components/LogSearch";
import TimerList from "@/components/timer/TimerList";
import TimerSettings from "@/components/timer/TimerSettings";
import RulesList from "@/components/rule/RulesList";
import RuleSettings from "@/components/rule/RuleSettings";
import ZoneList from "@/components/zone/ZonesList";
import ZoneSettings from "@/components/zone/ZoneSettings";
import FloorPlansList from "@/components/floorplan/FloorPlansList";
import FloorPlanSettings from "@/components/floorplan/FloorPlanSettings";
import FloorPlanDashboard from "@/components/floorplan/FloorPlanDashboard";
import SettingsLinks from "@/components/SettingsLinks";
import ModuleDetails from "@/components/saxamodule/ModuleDetails";

Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  {path: '/', component: Login},
  {path: '/unknownUser', component: UnknownUser},
  {path: '/dashboard', component: FloorPlanDashboard},
  {path: '/dashboard/:id', component: FloorPlanDashboard},
  {path: '/controller', component: ControllerDetails},
  {path: '/modules', component: ModulesList},
  {path: '/search-modules', component: SearchModules},
  {path: '/devices', component: DeviceList},
  {path: '/device/new/:moduleId/:terminalType/:pinId', component: DeviceSettings},
  {path: '/device/:id/settings', component: DeviceSettings},
  {path: '/device/:id/status', component: DeviceStatus},
  {path: '/module/:id/details', component: ModuleDetails},
  {path: '/timers', component: TimerList},
  {path: '/timer/new', component: TimerSettings},
  {path: '/timer/:id/settings', component: TimerSettings},
  {path: '/rules', component: RulesList},
  {path: '/rule/new', component: RuleSettings},
  {path: '/rule/:id/settings', component: RuleSettings},
  {path: '/zones', component: ZoneList},
  {path: '/zone/new', component: ZoneSettings},
  {path: '/zone/:id/settings', component: ZoneSettings},
  {path: '/floor-plans', component: FloorPlansList},
  {path: '/floor-plan/new', component: FloorPlanSettings},
  {path: '/floor-plan/:id/settings', component: FloorPlanSettings},
  {path: '/log', component: LogSearch},
  {path: '/settings', component: SettingsLinks},
];
export const router = new VueRouter({routes, mode: 'history'})
window.axios = require('axios')
window.axios.defaults.baseURL = process.env.VUE_APP_BASEURL
window.axios.defaults.withCredentials = true // ilma selleta jätab miskipärast JSESSIONID saatmata ja server ei saa sessiooni kätte
window.isMounted = false // avoid multiple invocations of Mixin.mounted()
window.isCreated = false // avoid multiple invocations ov Mixin.created()

export const ERROR_LABELS = {
	MISSING: 'Tühi',
	TOO_LONG: 'Liiga pikk',
	INVALID_EMAIL: 'Vigane e-posti aadress',
	RANGE: 'Vigane vahemik',
	OTHER: 'Proovi midagi muud',
	SAVE_OK: 'Muudatused salvestatud',
	FORM_ERRORS: 'Salvestamine ebaõnnestus, vaata andmed üle!',
}

Vue.mixin({
	mounted() {
		if (window.isMounted)
			return
		M.AutoInit()
		M.updateTextFields()//text inputtide labelid väikseks
		M.Tabs.init(document.querySelectorAll('.tabs'))
		window.isMounted = true
	},
	created() {
		if (window.isCreated)
			return
		window.axios.interceptors.request.use((config) => {
			this.showMask()
			this.$data.errors = {}
			++this.$data.pendingRequests
			return config
		}, (error) => {
			return Promise.reject(error);
		});
		window.axios.interceptors.response.use((response) => {
			if (--this.$data.pendingRequests == 0)
				this.hideMask()
			this.markSelectWrappers(response.config.method)
			return response
		}, (error) => {
			if (--this.$data.pendingRequests == 0)
				this.hideMask()

			if (error.response != null && error.response.status == 422) {//Unprocessable entity - sisendi valideerimise viga
				this.$data.errors = Object.fromEntries(Object.entries(error.response.data.fieldErrors).
					map(e => [e[0], ERROR_LABELS[e[1]] || ERROR_LABELS.OTHER]))
				this.markSelectWrappers(error.response.config.method)
				M.toast({html: ERROR_LABELS.FORM_ERRORS})
			}
			else if (error.response != null && error.response.status == 401) {//Unauthorized - sessioon sai läbi
				if (!this.$data.isLoginPage)
					window.location.href = "/"
			}
			else {//muude vigade puhul veakast
				let errorDetails = {
					message: error.message,
					url: error.config.baseURL + error.config.url,
					method: error.config.method.toUpperCase(),
					statusCode: (error.response ? error.response.status : "Ei saanud vastust"),
					responseData: (error.response && error.response.data ? JSON.stringify(error.response.data) : "Ei saanud andmeid")
				}
				this.$data.errorDetails = errorDetails;
				let errorboxElem = document.querySelectorAll('.error-box')[0]
				let instanceBox = M.Modal.getInstance(errorboxElem)
				instanceBox.open()
			}
			return Promise.reject(error);
		})
		window.isCreated = true
	},
	computed: {
		console: () => console,
		document: () => document,
		window: () => window,
		V: () => Vue,
		M: () => M,
	},
	data() {
		return {
			// .env faili väärtused
			env: {
				VUE_APP_UIBASEURL: process.env.VUE_APP_UIBASEURL,
				VUE_APP_BASEURL: process.env.VUE_APP_BASEURL,
				VUE_APP_WSURL: process.env.VUE_APP_WSURL,
			},
			isLoginPage: false,//vajalik selleks, et login lehel ei kuvaks serveri ühenduse puudumise teadet
			isInitialLoadingDone: false,//vajalik selleks, et container-DIV muutuks nähtavaks siis kui andmed on kohal
			pendingRequests: 0,//kuimitu AJAX-päringut on töös - vajalik maski jaoks
			errorDetails: {},// väärtused modaalse veakasti jaoks
			errors: {},// vormi valideerimise vead
		}
	},
	methods: {
		markSelectWrappers(method) {
			// Materialize/vue ei suuda korralikult SELECT'de vigu kuvada.
			// See meetod propageerib pärast iga POST/PUT'i SELECT-st invalid-classi vastavasse SELECT'i wrapperisse (või siis eemaldab sealt)
			switch(method) {
				case "PUT": case "POST": case "put": case "post": case "initSelects":
					break
				default:
					return
			}
			this.$nextTick(() => {
				document.querySelectorAll('.select-wrapper > select').forEach(selectEl => {
					if (selectEl.classList.contains('invalid'))
						selectEl.parentNode.classList.add('invalid')
					else selectEl.parentNode.classList.remove('invalid')
				})
			})
		},
		updateWidgets() {
			this.$nextTick(() => {
				M.updateTextFields()
				this.markSelectWrappers('initSelects')
				const elems = document.querySelectorAll('.tooltipped')
				M.Tooltip.init(elems)
			})
		},
		showMask() {
			this.document.getElementById('data-is-loading').style.visibility = 'visible'
		},
		hideMask() {
			this.$nextTick(() => this.document.getElementById('data-is-loading').style.visibility = 'hidden')
			this.$root.isInitialLoadingDone = true
		},
		setError(id, errorMessage) {
			this.$data.errors[id] = errorMessage
			this.markSelectWrappers('initSelects')
		},
		showSaveOk(message) {
			this.M.toast({
				html: '<i class="fas fa-check"></i> ' + message,
				outDuration: 4000
			})
		},
		clearErrors() {
			this.$data.errors = {}
			this.markSelectWrappers('initSelects')
		},
		focus(element) {setTimeout(() => element.focus(), 150)}
	}
})

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
