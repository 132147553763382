<template>
  <div>
    <div>
      <h1 v-if="!settings.name">Uus reegel</h1>
      <h1 v-if="settings.name">{{ settings.name }}</h1>
    </div>

    <div class="row">
      <form-input id="name" class="s12" placeholder="nt. Ukse avamise reegel" label="Reegli nimetus" v-model="settings.name"></form-input>
      <form-text-area css="s12" id="notes" placeholder="Siia saad lisada reegli kohta märkusi" label="Märkused" v-model="settings.notes"></form-text-area>
    </div>

    <collapsible>
      <li id="events">
        <div class="collapsible-header">
          <i class="fas fa-magic"></i>
          Trigerid
          <span class="incorrect-section right valign-wrapper" v-show="isTriggerError">Täitmata <i class="fas fa-exclamation-triangle"></i></span>
        </div>
        <div class="collapsible-body">
          <div class="row">
            <form-select id="type" :css="triggerTypeCss" label="Reegel käivitub, kui" v-model="settings.conf.combination" :options="typeOptions"></form-select>
            <form-input v-show="showTriggerTimeout" id="multiTriggerTimeout" css="s12 m2" label="Trigerdusaeg (ms)" v-model="settings.conf.multiTriggerTimeout"></form-input>
          </div>
          <rule-settings-section id="trigger" v-model="triggers" :options="itemOptions" :item-refs="itemRefs"
            label-item="Sündmuse tekitaja" label-status="Sündmus" icon="fas fa-magic"
            label-empty="Lisa sündmused, mis seda reeglit käivitavad" :status-label-provider="labelEvent"></rule-settings-section>
        </div>
      </li>
      <li id="filter">
        <div class="collapsible-header"><i class="fas fa-filter"></i>Käivitumise eeltingimused</div>
        <div class="collapsible-body">
          <rule-settings-section id="filter" v-model="filters" :options="itemOptions" :item-refs="itemRefs"
            label-item="Seade/moodul/taimer" label-status="Soovitud olek" icon="fas fa-filter"
            label-empty="Siia saab vajadusel lisada eeltingimusi, mis peavad olema täidetud reegli käivitumiseks"
            :status-label-provider="labelFilter"></rule-settings-section>
        </div>
      </li>
      <li id="commands">
        <div class="collapsible-header">
          <i class="fas fa-share-square"></i>
          Väljundkäsklused
          <span class="incorrect-section right valign-wrapper" v-show="isCommandError">Täitmata <i class="fas fa-exclamation-triangle"></i></span>
        </div>
        <div class="collapsible-body">
          <rule-settings-section id="command" v-model="commands" :options="outputItemOptions" :item-refs="itemRefs"
            label-item="Seade/moodul/taimer" label-status="Käsklus" icon="fas fa-share-square"
            label-empty="Siia saab lisada käsklused, mis saadetakse välja pärast reegli käivitamist"
            :has-delay="true" :status-label-provider="labelCommand"></rule-settings-section>
        </div>
      </li>
    </collapsible>

    <save-cancel-bar :save-handler="save"></save-cancel-bar>

  </div>
</template>

<script>
import SaveCancelBar from "@/components/shared/SaveCancelBar";
import FormTextArea from "@/components/shared/FormTextArea";
import FormInput from "@/components/shared/FormInput";
import FormSelect from "@/components/shared/FormSelect";
import Collapsible from "@/components/shared/Collapsible";
import RuleSettingsSection from "@/components/rule/RuleSettingsSection";
import Labels from "@/assets/js/labels"
import M from "materialize-css"

export default {
  name: "RuleSettings",
  components: {RuleSettingsSection, Collapsible, FormSelect, SaveCancelBar, FormTextArea, FormInput},
  data() {
    return {
      settings: {
        id: null,
        name: null,
        notes: null,
        conf: {
          combination: null,
        },
      },
      idSequence: 0,
      triggers: [],
      filters: [],
      commands: [],
      typeOptions: [
        {id: "any", label: "toimub mistahes nendest sündmustest"},
        {id: "all", label: "on toimunud kõik need sündmused"},
        {id: "sequence", label: "on toimunud kõik need sündmused samas järjekorras"},
      ],
      itemRefs: {},//mäp, kust id alusel saab kätte itemi nime ja tüübi
      itemOptions: [],//trigerite ja filtrite itemite valikud
      outputItemOptions: [],//käskude itemite valik
    }
  },
  mounted() {
    let url = "/rule/new"
    if (!this.window.location.pathname.endsWith("/new"))
      url = "/rule/" + this.window.location.pathname.split('/').slice(-2)[0] + "/settings"
    window.axios.get(url).then(response => {
      this.$data.itemRefs = {}
      response.data.itemOptions.forEach(g => g.options.forEach(
          opt => this.$data.itemRefs[opt.id] = {id: opt.id, label: opt.label, type: g.group, deviceType: opt.extraData}
      ))
      this.$data.itemOptions = Object.entries(this.$data.itemRefs).map(([k, v]) => ({id: k, label: v.label, type: v.type, deviceType: v.deviceType}))
      this.$data.outputItemOptions = this.$data.itemOptions.filter(opt => opt.type === 'Devices' || opt.type === 'Zones')
      this.$data.settings = response.data
      this.$data.triggers = response.data.conf.triggers.map(t => ({id: ++this.$data.idSequence, itemId: t.id, status: t.status}))
      this.$data.commands = response.data.conf.commands.map(c => ({id: ++this.$data.idSequence, itemId: c.id, type: c.type, status: c.status, delay: c.delay}))
      if (response.data.conf.prerequisites != null)
        this.$data.filters = Object.entries(response.data.conf.prerequisites).map(e => ({id: ++this.$data.idSequence, itemId: e[0], status: e[1][0]}))
      else this.$data.filters = []
      this.updateWidgets()
    })
  },
  computed: {
    isTriggerError() {
      return (this.$data.triggers.length == 0) || !this.$data.settings.conf.combination
    },
    isCommandError() {
      return (this.$data.commands.length == 0)
    },
    triggerTypeCss() {
      switch(this.$data.settings.conf.combination) {
        case 'all': case 'sequence': return "s12 m10"
        default: return "s12"
      }
    },
    showTriggerTimeout() {
      switch(this.$data.settings.conf.combination) {
        case 'all': case 'sequence': return true
        default: return false
      }
    },
  },
  methods: {
    save() {
      if (this.$data.triggers.length == 0 || this.$data.commands.length == 0) {
        M.toast({html: "Osa sektsioone on täitmata"})
        return
      }
      const data = JSON.parse(JSON.stringify(this.$data.settings))
      data.itemOptions = null
      data.conf.triggers = this.$data.triggers.map(t => ({id: t.itemId, status: t.status}))
      data.conf.commands = this.$data.commands.map(t => ({id: t.itemId, type: t.type, status: t.status, delay: t.delay}))
      data.conf.prerequisites = Object.fromEntries(this.$data.filters.map(item => [item.itemId, [item.status]]))
      window.axios.put("/rule/save", data).then(this.gotoRulesList)
    },
    gotoRulesList() {
      window.location.href = "/rules"
    },
    labelEvent(status) {
      return Labels.EVENT_LABELS[status] || status
    },
    labelFilter(status) {
      return Labels.STATUS_LABELS[status] || status
    },
    labelCommand(status) {
      return Labels.COMMAND_LABELS[status] || status
    },
  },
}
</script>

<style scoped lang="scss">
.collapsible-header i, .collapsible-header svg {margin-right: 1rem; font-size: 1.5rem}
.collapsible-header {display: block}
.collapsible-body {padding-bottom: 0px}
.incorrect-section {color: $error-color}
.incorrect-section svg, .incorrect-section i {margin-left: 0.5rem}
</style>
