<template>
  <div class="errorbar" v-if="controllerOffline || serverOffline">
    <div class="errorbar-content">
      <div class="left btn-floating btn-small white pulse"><i class="material-icons small icon-margin red-text">priority_high</i></div>
      <div class="right" v-if="controllerOffline">Ei saa kontrolleriga ühendust, vt <a href="/controller">siia!</a></div>
      <div class="right" v-else-if="serverOffline">Ei saa serveriga ühendust, <a href="/">logi uuesti sisse!</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorBar",
  props: {
    controllerOffline: {type: Boolean, required: false, default: false},
    serverOffline: {type: Boolean, required: false, default: false},
  }
}
</script>

<style scoped lang="scss">
.errorbar {
  position: fixed;
  height: 3rem;
  background-color: $error-color;
  color: white;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  z-index: 10;
}
.errorbar-content {
  width: fit-content;
  margin: 0.5em auto auto auto;
}
.errorbar-content .right {padding: 5px 0 0 1em;}
.errorbar a, errorbar a:visited, errorbar a:active {color: white; text-decoration: underline}
</style>
