import { render, staticRenderFns } from "./SettingsLinks.vue?vue&type=template&id=ec669150&scoped=true&"
import script from "./SettingsLinks.vue?vue&type=script&lang=js&"
export * from "./SettingsLinks.vue?vue&type=script&lang=js&"
import style0 from "./SettingsLinks.vue?vue&type=style&index=0&id=ec669150&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec669150",
  null
  
)

export default component.exports