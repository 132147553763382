<template>
  <table class="striped">
    <thead>
      <tr>
        <th></th>
        <th>Aeg</th>
        <th v-if="!!names">Mis</th>
        <th>Sündmus</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="log in list" :key="log.id">
        <td>
          <circle-icon :name="log.icon" :title="log.id"></circle-icon>
        </td>
        <td>{{ log.localTime }}</td>
        <td v-if="!!names">{{ log.originName }}</td>
        <td>{{ log.label }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import CircleIcon from "@/components/shared/CircleIcon";

export default {
  name: "LogList",
  components: {CircleIcon},
  props: ["list", "names", "showNames"],
}
</script>

<style scoped lang="scss">
.log-icon {
  border: 2px solid $icon-color;
  width: 30px; height: 30px;
  padding: 5px;
  color: $icon-color;
}
</style>
