<template>
  <div class="input-field col" :class="css">
    <input :id="id" type="text" class="timepicker"
           :placeholder="placeholder"
           :value="value"
           @change="onChange">
    <label :for="id" :class="{active: !!value}">{{ label }}</label>
    <span class="helper-text" :data-error="$root.errors[id]"></span>
  </div>
</template>

<script>
import M from "materialize-css";

export default {
  name: "TimePicker",
  props: {
    id: {type: String, required: true},
    css: {type: String, required: false},
    value: {type: String, required: false},
    label: {type: String, required: true},
    placeholder: {type: String, required: false},
  },
  mounted() {
    const elem = document.getElementById(this.id)
    M.Timepicker.init(elem, {twelveHour: false, i18n: {cancel: 'Tühista', clear: 'Puhasta', done: 'Ok'}})
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.value)
      this.$emit('change', event.target.value)
    },
  },
}
</script>
