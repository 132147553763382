<template>
  <div>
    <add-button route="/floor-plan/new"></add-button>
    <h1>Korruseplaanid</h1>
    <list-filter @change="applyFilterToList"></list-filter>

    <empty-list-placeholder :list="list" label="Ühtegi korruseplaani pole veel seadistatud. Lisa esimene korruseplaan <a href='/floor-plan/new'>siit</a>!"></empty-list-placeholder>
    <ul class="collection">
      <collection-item-with-status v-for="item in list" :key="item.id" :item="item" :url="'/floor-plan/'+item.id+'/settings'"
                                   :on-delete="() => deletePlan(item.id)" :on-icon-click="() => toggle(item)" v-show="!item.hidden"
                                   fixed-icon="fas fa-home">
        <i class="fas fa-info-circle"></i> {{ item.statusLabel }}
      </collection-item-with-status>
    </ul>
  </div>

</template>

<script>
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import BaseStatusCollection from "@/components/shared/BaseStatusCollection";
import CollectionItemWithStatus from "@/components/CollectionItemWithStatus";
import AddButton from "@/components/shared/AddButton";
import ListFilter from "@/components/shared/ListFilter";
export default {
  name: "FloorPlansList",
  extends: BaseStatusCollection,
  components: {ListFilter, AddButton, CollectionItemWithStatus, EmptyListPlaceholder},
  data() {
    return {
      list: [],
    }
  },
  mounted() {this.fetchListFrom('/floor-plans')},
  methods: {
    deletePlan(id) {window.axios.delete("/floor-plan/" + id).then(() => window.location.reload())},
  },
}
</script>
