<template>
  <div class="secondary-content">
    <a :href="url" @click="onClick">
      <i v-if="isMaterial" class="material-icons icon" :title="title">{{ icon }}</i>
      <i v-if="!isMaterial" class="icon icon-fa" :class="icon" :title="title"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "RowButton",
  props: {
    icon: {type: String, default: "insert_chart"},
    url: {type: String, default: "javascript:e => e.preventDefault()"},
    title: {type: String},
    clickHandler: {type: Function}
  },
  computed: {
    isMaterial() {
      return !this.icon.startsWith('fa')
    }
  },
  methods: {
    onClick() {
      this.clickHandler()
    }
  }
}
</script>

<style scoped>
.icon {font-size: 1.5rem}
</style>
