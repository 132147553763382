export default {
	statusIcons: {
		ok: 'fas fa-check',
		error: 'fas fa-hammer',
		alert: 'fas fa-exclamation-triangle',

		off: 'far fa-lightbulb',
		on: 'fas fa-lightbulb',

		unlocked: 'fas fa-lock-open',
		locked: 'fas fa-lock',

		open: 'fas fa-door-open',
		closed: 'fas fa-door-closed',

		pressed: 'fas fa-hand-point-up',
		released: 'far fa-hand-paper',

		silent: 'fas fa-street-view',
		movement: 'fas fa-running',

		freezing: 'far fa-snowflake',
		cold: 'fas fa-thermometer-empty',
		warm: 'fas fa-thermometer-three-quarters',
		hot: 'fas fa-thermometer-full',

		day: 'brightness_high',
		morning: 'brightness_low',
		afternoon: 'brightness_high',
		evening: 'brightness_medium',
		night: 'brightness_3',

		idle: 'fas fa-ban',
		pumping: 'fas fa-fan',
		running: 'fas fa-fan',

		dark: 'brightness_2',
		dim: 'brightness_4',
		bright: 'brightness_6',

		dry: 'fas fa-bacon',
		humid: 'fas fa-tint',

		vacant: 'fas fa-check',
		occupied: 'fas fa-ban',
		disarmed: 'fas fa-user-friends',
		armed: 'fab fa-shield-alt',
		arming: 'fas fa-user-clock',
		alarm: 'fas fa-bullhorn',
		relaxed: 'fas fa-coffee',
		party: 'fas fa-glass-cheers',
		romantic: 'far fa-heart',
		movienight: 'fas fa-film',
		workmode: 'fas fa-diagnoses',//fas fa-people-carry

		lightsOff: 'fas fa-moon',
		spotLight: 'fas fa-map-pin',
		backlight: 'fas-fa-adjust',
		maxLights: 'far fa-lightbulb',
		autoLight: 'fas fa-walking',

		online: 'fas fa-microchip',
		offline: 'fas fa-microchip error',
		unknown: 'fas fa-question',
	},
	eventIcons: {
		toggle: 'fas fa-hand-point-up',
		controllerConnected: 'fas fa-link',
		controllerDisconnected: 'fas fa-exclamation-triangle',
		controllerDead: 'fas fa-exclamation-triangle',
		commandError: 'far fa-frown',
	},
}
