<template>
  <div>
    <h1>Sündmuste ajalugu</h1>
    <div class="row">
      <time-picker id="time" label="Alates kellast" css="s12" v-model="time" @change="timeChanged"></time-picker>
    </div>
    <log-list :list="log" :names="names"></log-list>
  </div>
</template>

<script>
import LogFormat from "@/assets/js/logformat";
import LogList from "@/components/LogList";
import TimePicker from "@/components/shared/TimePicker";

export default {
  name: "LogSearch",
  components: {TimePicker, LogList},
  data() {
    return {
      time: null,
      names: {},
      log: [],
    }
  },
  mounted() {
    window.axios.get("/item-names").then(response => {
      response.data.forEach(group => group.options.forEach(option => this.$data.names[option.id] = option.label))
      window.axios.get("/account-log").then(this.updateList)
    })
  },
  methods: {
    changed(event) {
      if (event.type === 'changed' && !event.status)
        return
      this.$data.log = [LogFormat.toRecord(event, this.$data.names), ...this.$data.log]
      if (this.$data.log.length > 100)
        this.$data.log.pop()
    },
    updateList(response) {
      this.$data.log = response.data.map(record => LogFormat.formatRecord(record, this.$data.names))
      this.updateWidgets()
    },
    timeChanged() {
      console.log('CP1')
      window.axios.get("/account-log?time=" + this.$data.time).then(this.updateList)
    }
  },
}
</script>

<style scoped>

</style>
