<template>
  <div id="log">
    <ul class="collection">
      <li class="collection-item" v-for="item in list" :key="item.id" >
        <div class="row">
          <div class="col s6 left-align">{{ item.type }}</div>
          <div class="col s6 left-align">{{ item.timestamp }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ControllerLog",
  data() {
    return {
      list: []
    }
  },
  mounted() {
    window.axios.get("/controller-log").then(response => {
      this.$data.list = response.data
    })
  },
}
</script>

<style scoped>

</style>
