<template>
  <div>
    <h1>Moodulite otsing</h1>

    <div v-if="!$parent.principal.controllerOffline">
      <button-bar v-if="!searchInProgress">
        <icon-button label="Uus otsing" icon="cached" css="right" v-bind:handler="startSearch"></icon-button>
      </button-bar>

      <h2 v-if="searchInProgress">Otsing pooleli</h2>
      <h2 v-else>Otsinguga leitud</h2>
      <div class="progress" v-if="searchInProgress">
        <div class="determinate" v-bind:style="{width: searchStatus + '%'}"></div>
      </div>
      <div v-else-if="!newModules || newModules.length == 0" class="valign-wrapper center-align">
        <i class="material-icons medium">report_problem</i> Ei leidnud ühtegi uut moodulit
      </div>
      <ul class="collection" v-else>
        <li class="collection-item avatar valign-wrapper" v-for="item in newModules" :key="item.serial">
          <circle-icon name="fas fa-microchip"></circle-icon>
          {{ item.serial }} / {{ item.modelId }}
          <row-button icon="fas fa-directions" title="Registreeri" :click-handler="() => registerModule(item)"></row-button>
        </li>
      </ul>
    </div>
    <div v-else class="valign-wrapper">
      <i class="material-icons medium">warning</i>&nbsp;Moodulite otsingut ei saa teostada, sest puudub ühendus kontrolleriga
    </div>

    <h4>Registreeritud</h4>
    <empty-list-placeholder :list="registeredModules" label="Ükski moodul pole veel registreeritud"></empty-list-placeholder>
    <div class="row">
      <div class="col s6 m4 l2" v-for="item in registeredModules" :key="item.id">
        <div class="card">
          <b>{{ item.name }}</b><br>
          <span class="truncate">{{ item.type }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBar from "@/components/shared/ButtonBar";
import IconButton from "@/components/shared/IconButton";
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import RowButton from "@/components/shared/RowButton";
import CircleIcon from "@/components/shared/CircleIcon";
export default {
  name: "SearchModules",
  components: {CircleIcon, RowButton, EmptyListPlaceholder, IconButton, ButtonBar},
  progressTimer: null,
  data() {
    return {
      registeredModules: [],
      newModules:[],
      searchStatus: 0,
      searchStartTime: 0,
      searchEndTime: 0,
      searchInProgress: true,
    }
  },
  mounted() {
    window.axios.get("/modules").then(response => {
      this.$data.registeredModules = response.data
    })
    if (!this.$data.serverOffline)
      this.startSearch("fast")
  },
  methods: {
    searchResult(event) {//töötle searchResponse-sündmust
      this.$data.newModules = event.value
      this.$data.searchInProgress = false
    },
    startSearch() {
      window.axios.post("/search-modules").then(response => {
        this.$data.searchStartTime = new Date().getTime()
        this.$data.searchStatus = 0
        this.$data.searchEndTime = new Date().getTime() + response.data
        this.$data.searchInProgress = true
        this.progressTimer = setInterval(() => {
          let time = new Date().getTime()
          if (time > this.$data.searchEndTime) {
            clearInterval(this.progressTimer)
            this.$data.searchInProgress = false
          }
          else this.$data.searchStatus = 100 * (time - this.$data.searchStartTime) / (this.$data.searchEndTime - this.$data.searchStartTime)
        }, 250)
      })
    },
    registerModule(item) {
      window.axios.post("/register-module", item).then(response => {
        this.$data.registeredModules = response.data
        this.$data.newModules = this.$data.newModules.filter(module => module.serial != item.serial)
      })
    }
  },
}
</script>

<style scoped>
.card {height: 4rem; padding: 5px}
</style>
