<template>
  <div>
    <add-button route="/search-modules"></add-button>
    <h1>Moodulid</h1>
    <list-filter @change="applyFilterToList"></list-filter>

    <empty-list-placeholder :list="list" label="Ühtegi moodulit pole veel ühendatud. Lisa <a href='/search-modules'>siit</a> esimene moodul!"></empty-list-placeholder>
    <ul class="collection">
      <collection-item-with-status v-for="item in list" :key="item.id" :item="item" :url="'/module/'+item.id+'/details'"
                                   :on-delete="() => deleteModule(item.id)" v-show="!item.hidden">
        {{ item.type }}
        <i class="fas fa-info-circle"></i> {{ item.statusLabel }}
      </collection-item-with-status>
    </ul>
  </div>
</template>

<script>
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import CollectionItemWithStatus from "@/components/CollectionItemWithStatus";
import BaseStatusCollection from "@/components/shared/BaseStatusCollection";
import AddButton from "@/components/shared/AddButton";
import ListFilter from "@/components/shared/ListFilter";
export default {
  name: "ModulesList",
  extends: BaseStatusCollection,
  components: {ListFilter, AddButton, CollectionItemWithStatus, EmptyListPlaceholder},
  mounted() {this.fetchListFrom('/modules')},
  methods: {
    search() {
      window.axios.post("/moduleSearch").then(() => {})
    },
    deleteModule(id) {
      window.axios.delete("/module/" + id).then(() => window.location.reload())
    },
  },
}
</script>

<style scoped lang="scss">
.item-status i, .item-status svg {margin-left: 1rem}
.item-name {font-weight: bold}
.status-online, .status-offline {margin-left: 2rem}
.status-offline {color: $error-color}
</style>
