import Icons from "@/assets/js/iconmap"
import Labels from "@/assets/js/labels"

export default {
	/**
	 * Teisendab saabunud sündmuse logikirjeks, et seda saaks logis esitada
	 * @param event saabunud sündmus
	 * @param names objektide id->nimi mäp
	 * @returns täiendatud logikirje
	 */
	toRecord(event, names) {
		event.originId = event.id
		event.id = new Date().getTime()
		event.timestamp = new Date().toISOString().slice(0, -1)
		return this.formatRecord(event, names)
	},
	/**
	 * Vormindab ühe logikirje nii, et LogList saaks seda esitada
	 * @param record logikirje andmebaasist
	 * @param names objektide id->nimi mäp
	 * @returns täiendatud logikirje
	 */
	formatRecord(record, names) {
		record.icon = this.icon(record)
		record.localTime = this.formatTime(record.timestamp)
		record.label = this.label(record)
		record.originName = (names != null ? names[record.originId] : null)
		return record
	},
	icon(record) {
		// Ikoon sõltub kirje tüübist ja staatusest
		if (record.type === 'changed')
			return Icons.statusIcons[record.status] || 'far fa-file-alt'
		else return Icons.eventIcons[record.type] || 'far fa-file-alt'
	},
	formatTime(timestamp) {
		var datepart;
		const date = new Date(timestamp)
		if (timestamp.startsWith(this.TODAY))
			datepart = ', täna'
		else if (timestamp.startsWith(this.YESTERDAY))
			datepart = ', eile'
		else datepart = ', ' + date.toLocaleString(navigator.language).split(' ')[0]
		return date.toLocaleTimeString(navigator.language) + datepart
	},
	label(record) {
		const lbl = Labels.EVENT_LABELS[record.type + '_' + record.status] || '?'
		return (lbl.startsWith('!') ? record.actorName + lbl.slice(1) : lbl)
	},
	TODAY: new Date().toISOString().slice(0, 10),
	YESTERDAY: new Date(new Date().getTime()-24*60*60*1000).toISOString().slice(0, 10),
}
