<template>
  <div>
    <add-button route="/zone/new"></add-button>
    <h1>Tsoonid</h1>
    <list-filter @change="applyFilterToList"></list-filter>

    <empty-list-placeholder :list="list" label="Ühtegi tsooni pole veel seadistatud. Lisa esimene tsoon <a href='/zone/new'>siit</a>!"></empty-list-placeholder>
    <ul class="collection">
      <collection-item-with-status v-for="item in list" :key="item.id" :item="item" :url="'/zone/'+item.id+'/settings'"
                                   :on-delete="() => deleteZone(item.id)" :on-icon-click="() => toggle(item)" v-show="!item.hidden">
        <i class="fas fa-info-circle"></i> {{ item.statusLabel }}
      </collection-item-with-status>
    </ul>
  </div>
</template>

<script>
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import BaseStatusCollection from "@/components/shared/BaseStatusCollection";
import CollectionItemWithStatus from "@/components/CollectionItemWithStatus";
import AddButton from "@/components/shared/AddButton";
import ListFilter from "@/components/shared/ListFilter";

export default {
  name: "ZoneList",
  extends: BaseStatusCollection,
  components: {ListFilter, AddButton, CollectionItemWithStatus, EmptyListPlaceholder},
  data() {
    return {
      list: [],
    }
  },
  mounted() {this.fetchListFrom('/zones')},
  methods: {
    deleteZone(id) {window.axios.delete("/zone/" + id).then(() => window.location.reload())},
    toggle(item) {window.axios.post("/message", {id: item.id, type: 'toggle'})}
  },
}
</script>
