<template>
  <div>
    <div>
      <h1 v-if="!settings.name">Uus tsoon</h1>
      <h1 v-if="settings.name">{{ settings.name }}</h1>
    </div>

    <div class="row">
      <div class="col s12 m6">
        <h2>Tsooni üldomadused</h2>
        <div class="row">
          <form-input id="name" class="s12" placeholder="nt. Elutuba" label="Tsooni nimetus" v-model="settings.name"></form-input>
          <form-select v-show="!!settings.statuses" id="defaultStatus" css="s12" label="Vaikimisi aktiivne olek" :options="defaultOptions" v-model="settings.defaultStatus"></form-select>
          <form-text-area css="s12" id="notes" placeholder="Siia saad lisada tsooni kohta märkusi" label="Märkused" v-model="settings.notes"></form-text-area>
        </div>
      </div>
      <div class="col s12 m6">
        <h2>Tsooni võimalikud olekud</h2>
        <empty-list-placeholder :list="settings.statuses" label="Ühtki olekut veel pole. Et tsoon toimiks, on vaja lisada vähemalt 2 olekut" v-show="!entryVisible"></empty-list-placeholder>
        <ul class="collection">
          <li class="collection-item avatar valign-wrapper" v-for="status in settings.statuses" :key="status">
            <circle-icon name="far fa-bookmark"></circle-icon>
            {{ statusLabel(status) }}
            <row-button icon="fas fa-trash" :click-handler="() => discard(status)" title="Kustuta see olek"></row-button>
          </li>
          <li class="collection-item avatar valign-wrapper" v-show="!entryVisible" :class="{invalid: $root.errors['statuses']}">
            <circle-icon name="fas fa-plus" title="Alusta uue lisamist" :on-click="showEntry" v-show="!entryVisible" css="clickable"></circle-icon>
          </li>
          <li class="collection-item avatar entry-form" v-show="entryVisible">
            <circle-icon name="far fa-trash-alt" title="Tühista lisamine" :on-click="hideEntry" css="clickable"></circle-icon>
            <form-select id="addStatus" css="s12" label="Vali järgmine olek" :options="availableOptions" v-model="addStatus" @change="commit"></form-select>
          </li>
        </ul>
        <div class="input-field">
          <input v-show="false" type="text" :class="{invalid: $root.errors['statuses']}">
          <span class="helper-text" data-error="Vaja vähemalt 2 olekut"></span>
        </div>
      </div>
    </div>
    <save-cancel-bar :save-handler="save"></save-cancel-bar>
  </div>
</template>

<script>
import SaveCancelBar from "@/components/shared/SaveCancelBar";
import FormTextArea from "@/components/shared/FormTextArea";
import FormInput from "@/components/shared/FormInput";
import Labels from "@/assets/js/labels"
import FormSelect from "@/components/shared/FormSelect";
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import CircleIcon from "@/components/shared/CircleIcon";
import RowButton from "@/components/shared/RowButton";

export default {
  name: "ZoneSettings",
  components: {RowButton, CircleIcon, EmptyListPlaceholder, FormSelect, SaveCancelBar, FormTextArea, FormInput},
  mounted() {
    let url = "/zone/new"
    if (!this.window.location.pathname.endsWith("/new"))
      url = "/zone/" + this.window.location.pathname.split('/').slice(-2)[0] + "/settings"
    window.axios.get(url).then(response => {
      response.data.statuses = response.data.statuses || []
      this.$data.settings = response.data
      this.updateOptions()
    })
  },
  data() {
    return {
      availableOptions: [],
      defaultOptions: [],
      settings: {
        statuses: [],
        defaultStatus: null,
      },
      statusOptions: "vacant,occupied,disarmed,armed,arming,alarm,relaxed,party,romantic,movienight,workmode," +
          "lightsOff,spotLight,maxLights,autoLight",
      addStatus: null,
      entryVisible: false,
    }
  },
  methods: {
    updateOptions() {
      this.$data.availableOptions = this.$data.statusOptions.split(',')
        .filter(s => !this.$data.settings.statuses.includes(s))
        .map(s => ({id: s, label: Labels.STATUS_LABELS[s]}))
      this.$data.defaultOptions = this.$data.settings.statuses
        .map(s => ({id: s, label: Labels.STATUS_LABELS[s]}))
      if (!this.$data.settings.statuses.includes(this.$data.settings.defaultStatus))
        this.$data.settings.defaultStatus = null
      this.updateWidgets()
    },
    showEntry() {this.$data.entryVisible = true},
    hideEntry() {this.$data.entryVisible = false},
    commit() {
      this.$nextTick(() => {
        this.$data.settings.statuses.push(this.$data.addStatus)
        this.$data.entryVisible = false
        this.updateOptions()
      })
    },
    discard(status) {
      this.$data.settings.statuses = this.$data.settings.statuses.filter(element => element != status)
      this.$data.entryVisible = false
      this.updateOptions()
    },
    statusLabel(status) {
      return Labels.STATUS_LABELS[status]
    },
    save() {
      this.hideEntry()
      window.axios.put("/zone", this.$data.settings)
        .then(() => window.location.href = '/zones')
    },
  },
}
</script>

<style scoped lang="scss">
.entry-form .input-field, .entry-form .row {margin-bottom: 0px}
.collection .entry-form.collection-item.avatar {border-bottom: 0px}
.entry-form.collection-item.avatar .icon-wrapper {margin-top: 1rem}
.collection {overflow: visible} //et avatud dropdowni ära ei lõikaks
.collection .collection-item.invalid {border-bottom: 2px solid $error-color}//vea korral collection-itemile punane joon alla
</style>
