<template>
<div>
  <h1>Seadmed</h1>
  <list-filter @change="applyFilterToList"></list-filter>

  <empty-list-placeholder :list="list" label="Ühtegi seadet pole veel ühendatud"></empty-list-placeholder>
	<ul class="collection">
    <collection-item-with-status v-for="item in list" :key="item.id" :item="item" :url="'/device/'+item.id+'/status'"
                                 :on-delete="() => deleteDevice(item.id)" :on-icon-click="() => toggle(item)" v-show="!item.hidden">
      {{ item.type }}
      <i class="fas fa-info-circle"></i> {{ item.statusLabel }}
      <span v-if="!item.isOnOff && (item.value != null)"><i class="fas fa-tachometer-alt"></i> {{ toFixValue(item) }}{{ item.unit }}</span>
    </collection-item-with-status>
  </ul>
</div>
</template>

<script>
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import CollectionItemWithStatus from "@/components/CollectionItemWithStatus";
import BaseStatusCollection from "@/components/shared/BaseStatusCollection";
import ListFilter from "@/components/shared/ListFilter";

export default {
  name: "DeviceList",
  extends: BaseStatusCollection,
  components: {ListFilter, CollectionItemWithStatus, EmptyListPlaceholder},
  mounted() {this.fetchListFrom('/devices')},
  methods: {
    toggle(item) {
      if (item.type != 'lamp' && item.type != 'lock' && item.type != 'motor' && item.type != 'pump')
        return
      window.axios.post("/message", {id: item.id, type: 'toggle'})
    },
    deleteDevice(id) {window.axios.delete("/device/" + id).then(() => window.location.reload())},
    toFixValue(item) {
      if (item.value == null || (typeof item.value) != 'number')
        return item.value;
      else return item.value.toFixed(item.decimals)
    }
  },
}
</script>

<style scoped>
.item-status i, .item-status svg {margin-left: 1rem}
</style>
