<template>
  <ul class="collapsible popout">
    <slot></slot>
  </ul>
</template>

<script>
import M from "materialize-css";

export default {
  name: "Collapsible",
  mounted() {
    const elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {onOpenEnd: this.onOpenEnd});
  },
}
</script>

<style scoped>

</style>
