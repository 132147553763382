<template>
  <div>
    <div v-show="isListEmpty" class="empty-list valign-wrapper row">
      <div class="col s4 m2"><i class="fas fa-info-circle fa-4x"></i></div>
      <div class="col s8 m10" v-html="label" v-show="isListEmpty"></div>
    </div>
    <div v-show="isFiltered" class="empty-list valign-wrapper row">
      <div class="col s4 m2"><i class="fas fa-exclamation-triangle fa-4x"></i></div>
      <div class="col s8 m10">See filter peidab kõik read. Kustuta filter või vähenda piiranguid!</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyListPlaceholder",
  props: {
    list: {type: Array, required: false},
    label: {type: String, required: true},
  },
  computed: {
    isListEmpty() {return !this.list || this.list.length == 0},
    isFiltered() {return this.list && (this.list.length > 0) && !this.list.find(item => !item.hidden)}
  }
}
</script>

<style scoped lang="scss">
.empty-list {color: lighten($text-color, 30%); margin-top: 2rem}
</style>
