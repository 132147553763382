<template>
  <div class="filter-bar">
    <div class="no-filters" v-show="!entryActive && !$root.$data.filter">
      <i class="fas fa-filter"></i>
      <a @click="activateEntry">Filtreerimata</a>
    </div>
    <div class="filter-entry" v-show="entryActive">
      <i class="fas fa-filter"></i>
      <div class="filter-input-container">
        <input id="last_name" type="text" class="validate" v-model="entryText" ref="entry"
               @keydown.esc="discardEntry" @keydown.enter="applyEntry">
      </div>
      <a @click="applyEntry" class="tooltipped" data-tooltip="Rakenda"><i class="fas fa-check"></i></a>
      <a @click="dropFilter" class="tooltipped" data-tooltip="Kustuta filter"><i class="fas fa-eraser"></i></a>
      <a @click="discardEntry" class="tooltipped" data-tooltip="Tühista muudatused"><i class="fas fa-times"></i></a>
    </div>
    <div class="filter-active valign-wrapper" v-show="!entryActive && $root.$data.filter">
      <i class="fas fa-filter"></i>
      <a @click="activateEntry" class="valign-wrapper">
        <span class="active-info">Filtreeritud loend</span>
        <div class="chip" v-for="word in $root.$data.filter" :key="word">
          {{ word }}
        </div>
      </a>
      <a @click="dropFilter" class="tooltipped" data-tooltip="Kustuta filter"><i class="fas fa-eraser"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListFilter",
  mounted() {this.$data.entryText = this.$root.$data.filter},
  data() {
    return {
      entryActive: false,
      entryText: null
    }
  },
  methods: {
    applyEntry() {
      this.$root.$data.filter = (this.$data.entryText ? this.$data.entryText.split(' ').filter(w => w.length) : null)
      this.$data.entryActive = false
      this.$emit('change')
    },
    dropFilter() {this.$data.entryText = null; this.applyEntry()},
    discardEntry() {this.$data.entryText = this.$root.$data.filter; this.$data.entryActive = false},
    activateEntry() {
      this.$data.entryText = (this.$root.$data.filter ? this.$root.$data.filter.join(' ') : null)
      this.$data.entryActive = true
      this.focus(this.$refs.entry)
    }
  }
}
</script>

<style scoped lang="scss">
.no-filters, .no-filters a {color: #aaa}
.fa-filter {margin-right: 0.5rem}
.filter-bar {background-color: #eee; padding: 4px; border-radius: 4px}
.filter-bar svg {font-size: 1.2rem; margin-left: 0.5rem}
.filter-bar:hover a {color: $secondary-color; transition: all 0.2s linear}
.filter-input-container {display: inline-block; margin-left: 0.5rem; width: calc(100% - 8rem); font-size: 0.8rem}
.filter-input-container input[type=text] {height: 1.5rem; margin-top: -1px; margin-bottom: 5px; font-size: 1rem}
.filter-active a, .filter-active .chip {color: $primary-color}
.filter-active .chip {height: 1.5rem; line-height: 1.5rem; border-radius: .5rem; margin-bottom: 0; background-color: #ccc}
.filter-active .active-info {margin-left: 0.5rem; margin-right: 0.5rem}
</style>
