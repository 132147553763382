<template>
  <nav id="navbar">
    <div class="nav-wrapper">
      <div class="search input-field">
        <input id="search" type="search" ref="field" v-model="entry">
        <label class="label-icon" for="search"><i class="fas fa-search"></i></label>
      </div>
      <div class="action-icons">
        <a @click="$emit('change', entry)"><i class="fas fa-check"></i></a>
        <a @click="$emit('cancel')"><i class="fas fa-times"></i></a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SearchBar",
  mounted() {setTimeout(() => this.$refs.field.focus(), 100)},
  data() {return {entry: null}}
}
</script>

<style scoped lang="scss">
svg {font-size: 1.2rem; color: white}
.search.input-field input[type=search] {
  color: white;
  padding-left: 3rem;
  width: calc(100% - 6rem);
}
.search.input-field input[type=search]:focus {
  background-color: $primary-color;
  color: white;
  padding-left: 3rem;
  padding-right: 3rem
}
.action-icons {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 1rem;
  cursor: pointer
}
.action-icons a svg.svg-inline--fa {
  margin-left: 0.5rem;
  color: white;
}
</style>
