<template>
  <div class="floor-plan-dashboard">
    <div class="row">
      <div class="col s12 m4 l3 zone-actions" v-if="selectedZone">
        <h2>{{ selectedContourName }}</h2>
        <ul class="collection">
          <li class="collection-item avatar valign-wrapper" v-for="status in selectedZone.statuses"
              :key="status" :class="{'active': selectedZoneStatus===status}" @click="() => onSelectStatus(status)">
            <circle-icon :name="iconFor(status)"></circle-icon>
            {{ statusLabel(status) }}
          </li>
        </ul>
      </div>
      <div class="col s12 m8 l9">
        <pan-zoom-wrapper ref="panzoomWrapper" :image-url="imageUrl" :bg-color="response.bgColor"
                          :width="response.imageWidth" :height="response.imageHeight">
          <polygon v-for="contour in response.contours" :key="contour.id"
                   :fill="contour.color" fill-opacity="0.5" :stroke="contour.color" stroke-opacity="0.5"
                   stroke-width="0" stroke-linejoin="round" stroke-linecap="round" :points="polygon(contour)"
                   @click="() => onPolygonClick(contour)" @touchend="() => onPolygonClick(contour)"></polygon>
        </pan-zoom-wrapper>
      </div>
    </div>

    <div v-if="planNotFound">
      <empty-list-placeholder label="Ühtegi korruseplaani pole veel loodud. Korruseplaani sisestamiseks mine <a href=''>siia</a>"
        :list="listForMissingPlanMessage"></empty-list-placeholder>
    </div>
  </div>
</template>

<script>
import EmptyListPlaceholder from "@/components/shared/EmptyListPlaceholder";
import Labels from "@/assets/js/labels"
import Icons from "@/assets/js/iconmap"
import Const from "@/assets/js/const"
import CircleIcon from "@/components/shared/CircleIcon";
import PanZoomWrapper from "@/components/floorplan/PanZoomWrapper";

export default {
  name: "FloorPlanDashboard",
  components: {PanZoomWrapper, CircleIcon, EmptyListPlaceholder},
  computed: {
    listForMissingPlanMessage() {
      return (this.$data.response.id ? [123] : null)
    },
    selectedContourName() {
      if (!this.$data.selectedContour)
        return null;
      return this.$data.response.zones[this.$data.selectedContour.zoneId].name
    }
  },
  mounted() {
    const url = (this.$route.params.id ? '/floor-plan/' + this.$route.params.id + '/dashboard' : '/floor-plan/default-dashboard')
    window.axios.get(url).then(response => {
      if (!response.data.id) {
        this.$data.planNotFound = true
        return
      }
      let index = 0;
      let contours = response.data.contours = response.data.contours.map(contour => {
        contour.id = ++index;
        contour.color = 'transparent'
        return contour
      })
      response.data.zones = response.data.zones.reduce((result, item) => {
        result[item.id]=item;
        return result
      }, {})
      this.$data.response = response.data
      this.$data.imageUrl = `${this.$root.env.VUE_APP_BASEURL}/floor-plan/${response.data.id}/image/${response.data.imageUrlSuffix}`
      this.updateContourVisual(this.$data.selectedContour = contours.find(contour => contour.zoneId))
      this.$refs.panzoomWrapper.rezoom()
    })
  },
  data() {
    return {
      planNotFound: true,
      imageUrl: null,
      response: {
        id: null,
        contours: [],
        imageWidth: null,
        imageHeight: null,
        bgColor: null,
      },
      selectedContour: null,
      selectedZone: null,
      selectedZoneStatus: null,
    }
  },
  methods: {
    polygon(contour) {
      return contour.polygon.map(el => el.x+','+el.y).join(' ')
    },
    onPolygonClick(clickedContour) {
      if (clickedContour.zoneId) {
        this.$data.selectedContour = clickedContour
        this.updateContourVisual(clickedContour)
      }
      else if (this.$data.response.id !== clickedContour.planId)//kui klikib hetkel kuvatud plaanile, siis ei navigeeri lehelt minema
        window.location.href = '/dashboard/' + clickedContour.planId
    },
    updateContourVisual(selectedContour) {
      this.$data.selectedZone = (selectedContour ? this.$data.response.zones[selectedContour.zoneId] : null)
      this.$data.response.contours = this.$data.response.contours.map(contour => {
        let active = (selectedContour && (selectedContour.id === contour.id))
        if (contour.planId === this.$data.response.id)
          active = true
        contour.color = (active ? Const.secondaryColor : 'transparent')
        return contour
      })
      if (selectedContour && selectedContour.zoneId)
        window.axios.post("/message", {
          id: this.$data.selectedZone.id,
          type: 'snapshot',
          value: [selectedContour.zoneId]
        })
    },
    statusLabel(status) {
      return Labels.STATUS_LABELS[status]
    },
    onSelectStatus(status) {
      window.axios.post("/message", {id: this.$data.selectedZone.id, type: 'setStatus', status: status})
    },
    snapshotResponse(event) {
      if (!this.$data.selectedZone || !this.$data.selectedZone.statuses)
        return
      const itemEntry = event.value[this.$data.selectedZone.id]
      if (itemEntry)
        this.updateZoneStatus(itemEntry.status)
    },
    changed(event) {
      if (!this.$data.selectedZone || !this.$data.selectedZone.statuses || this.selectedZone.id != event.id)
        return
      this.updateZoneStatus(event.status)
    },
    updateZoneStatus(newStatus) {
      this.$data.selectedZoneStatus = newStatus
      this.$data.selectedZone.statuses = this.$data.selectedZone.statuses.map(s => s)//sunni loendit uuesti renderdama
    },
    iconFor(status) {
      return Icons.statusIcons[status] || 'far fa-bookmark'
    }
  }
}
</script>

<style>
.row .col.l9 {padding-left: 0px}/*hoia ekraani vasakääres ruumi kokku*/
</style>

<style scoped lang="scss">
li.active {font-weight: bold}
li.collection-item {cursor: pointer}
@media only screen and (max-height: $small-vertical-breakpoint) {/*vert.kitsal ekraanil jäta ruumi teistsuguse navi jaoks*/
  .zone-actions {margin-top: 1.5rem}
}
</style>
