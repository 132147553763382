<template>
  <div>
    <div v-if="groups" class="row">
      <div v-for="group in groups" :key="group.type" class="col s12 m6 l3">
        <h2>{{ grLbl(group.type) }}</h2>
        <ul class="collection">
          <li class="collection-item avatar valign-wrapper" :class="{disabled: !terminal.deviceId}"
              v-for="terminal in group.terminals" :key="terminal.pinId">
            <circle-icon name="fas fa-neuter" css="disabled" v-show="!terminal.deviceId"></circle-icon>
            <circle-icon name="fas fa-neuter" v-show="terminal.deviceId"></circle-icon>
            <div>
              <div class="item-name">
                {{ lbl(group.type) }} #{{ terminal.pinId }}
              </div>
              <div class="device-ref" v-show="terminal.deviceId">
                <span class="tooltipped" data-tooltip="Ühendatud"><i class="fas fa-plug"></i></span>
                <router-link :to="'/device/'+terminal.deviceId+'/settings'">{{ terminal.deviceName }}</router-link>
              </div>
            </div>
            <div class="secondary-content">
              <router-link :to="buildAddLink(group, terminal)" class="add-button" v-show="!terminal.deviceId">
                <i class="fas fa-plus-square"></i>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CircleIcon from "@/components/shared/CircleIcon";
import Labels from "@/assets/js/labels"

export default {
  name: "ModuleTerminals",
  components: {CircleIcon},
  methods: {
    update(settings) {this.$data.moduleGroups = settings.terminalGroups; this.mergeScanResult()},
    lbl(key) {return Labels.TERMINAL_LABELS[key]},
    grLbl(key) {return Labels.TERMINAL_GROUP_LABELS[key]},
    buildAddLink(group, terminal) {
      return `/device/new/${this.$route.params.id}/${group.type}/${terminal.pinId}`
    },
    notifyScanResponse(event) {
      if (event.id == this.$route.params.id) {
        this.$data.scanResult = event.value;
        this.mergeScanResult()
      }
    },
    mergeScanResult() {
      this.$data.groups = this.$data.moduleGroups.map(g => {
        if (g.type === 'i2c') {
          const group = {type: 'i2c'}
          const terminals = {}
          this.$data.scanResult.forEach(address => {terminals[address] = {pinId: address, deviceName: address}})//just skännitud seadmed
          g.terminals.forEach(t => terminals[t.pinId] = t)//lisa registreeritud seadmed
          group.terminals = Object.entries(terminals).map(e => e[1])//kirjuta i2c grupi seadmed üle
          return group
        }
        else return g
      })
    }
  },
  data() {
    return {
      groups: null,
      moduleGroups: [],
      scanResult: [],
    }
  }
}
</script>

<style scoped lang="scss">
.device-ref {text-decoration: underline; font-size: .8rem}
.disabled {color: $disabled-color}
.collection .collection-item.avatar {padding-left: 3rem; padding-right: 2rem}
.collection .collection-item.avatar .secondary-content {right: 0px}
.collection-item svg {font-size: 1.5rem; vertical-align: middle; margin-right: 0.5rem}
</style>
