<template>
  <div class="input-field col" :class="css">
    <input :id="id" type="text"
           :value="value"
           :class="{invalid: $root.errors[id], autocomplete: autocompleteOptions}"
           :placeholder="placeholder"
           :readonly="readonly"
           @input="$emit('input', $event.target.value)">
    <label :for="id">{{ label }}</label>
    <span class="helper-text" :data-error="$root.errors[id]"></span>
  </div>
</template>

<script>
export default {
  name: "FormInput",
  instance: null,
  props: {
    id: {type: String, required: true},
    css: {type: String, required: false},
    value: {required: false},
    label: {type: String, required: true},
    placeholder: {type: String, required: false},
    readonly: {type: Boolean, required: false},
    autocompleteOptions: {type: Array, required: false},
  },
  mounted() {
    if (this.autocompleteOptions) {
      const elem = document.querySelectorAll('#' + this.id)
      const opts = {}
      this.autocompleteOptions.forEach(o => opts[o] = null)
      this.instance = this.M.Autocomplete.init(elem, {data: opts})[0]
    }
  },
  watch: {
    autocompleteOptions() {
      const opts = {}
      this.autocompleteOptions.forEach(o => opts[o] = null)
      this.instance.updateData(opts)
    }
  },
}
</script>
